@import '../../../../styles/constants.scss';

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: $radius-small;
  margin-bottom: 12px;
  background: $primary-gray;

  &:last-child {
    margin-bottom: 0;
  }
}

.content {
  display: flex;
  align-items: center;
}

.stepIdentifier {
  padding: 6px 15px 6px 0;
  border-right: 1px solid #fff;
  margin-right: 12px;
  font-weight: 700;
}

.icon {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
}
