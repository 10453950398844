.item {
  margin-bottom: 36px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.list {
  width: 100%;
}
