@import '../../styles/constants.scss';
@import '../../styles/classes/heading.module.scss';
@import '../../styles/classes/text.module.scss';
@import '../@components/kit//form//button//styles.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  padding: 40px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  background-color: #fff;
}

.title {
  margin-bottom: 0;
  text-align: center;
  font-weight: 400;
  color: #000;
  composes: h3;
}

.text {
  margin-top: 24px;
  text-align: center;
  composes: paragraph;
}

.button {
  margin-top: 24px;
  composes: button;
}

.companyLogo {
  width: 50px;
  height: 50px;
  border: 1px solid $secondary-gray2;
  margin-top: 24px;
}
