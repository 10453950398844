@import '../../../../../styles/classes/text.module.scss';
@import '../../../../../styles/constants.scss';

.description {
  margin-top: 12px;
  composes: paragraph;
}

.item {
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: $radius-small;
  text-decoration: none;
  font-size: 16px;
  color: $semantic-link;
  transition: 0.3s ease;
  transition-property: background-color;

  &:hover,
  &:focus {
    background-color: $primary-gray;
  }
}

.checkbox {
  margin-right: 18px;
}

.checkboxInput {
  width: 24px;
  height: 24px;
  border: 1px solid #ddd;
  border-radius: $radius-half-circle;
  background-color: white;
  outline: none;
  appearance: none;
  cursor: pointer;

  &.completed {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    background-color: #002554;

    .checkboxIcon {
      display: block;
    }
  }
}

.checkboxIcon {
  display: none;
  width: 16px;
  height: 16px;
  color: #fff;
}

.title {
  margin-top: 2px;
}

.titleNote {
  margin-top: 2px;
  font-size: 12px;
  color: $semantic-success;
}

.completedAssessments {
  padding-left: 12px;
  margin-bottom: 12px;
  color: $semantic-success;
}
