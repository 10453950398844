@import '../../../../../../styles/constants.scss';

.container {
  position: relative;
  margin-right: 12px;
}

.button {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: $radius-half-circle;
  background: #fff;
}

.buttonIcon {
  width: 24px;
  height: 24px;
  color: $primary-blue;
}

.unreadNotifications {
  position: absolute;
  top: -2px;
  right: 0;
  opacity: 0;
  width: 15px;
  height: 15px;
  border-radius: $radius-half-circle;
  background: #d02b2b;
  transition: 0.3s ease;
  transition-property: opacity;

  &.isVisible {
    opacity: 1;
  }
}

.listContainer {
  position: absolute;
  z-index: 10;
  top: 55px;
  right: -5px;
  opacity: 0;
  transform: translateY(50px);
  width: 275px;
  border: 0.5px solid $secondary-gray2;
  border-radius: $radius-small;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(#000, 0.3);
  transition: 0.3s ease;
  transition-property: opacity, transform;
  pointer-events: none;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: -10px;
    right: 10px;
    width: 25px;
    height: 10px;
    background: #fff;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  &.isVisible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
}
