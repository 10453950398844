@import '../../../../../../../../styles/constants.scss';

.editionList {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 12px 0 0 24px;
  gap: 12px;

  @media screen and (max-width: $breakpoint-medium) {
    margin: 12px 0 0;
  }
}

.editListDisabled {
  color: $semantic-disabled;
  composes: editionList;
  cursor: not-allowed;

  label,
  [disabled] {
    cursor: not-allowed;
  }
}

.editionListItem {
  flex: 1 1 100%;
}

.subList {
  display: flex;
  align-items: center;
  gap: 12px;
}

.subListItem {
  flex: 1 0 calc(50% - 12px);
}
