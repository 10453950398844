@import '../../../../../../../../../../styles/constants.scss';
@import '../../../../../../../../../../styles/classes/heading.module.scss';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 390px;
  padding: 12px 24px 12px 12px;
  border: 1px solid $secondary-gray;
  border-radius: $radius-medium;
  margin-bottom: 6px;
}

.content {
  width: 100%;
  max-width: 246px;
  margin: 0 12px;
}

.picture {
  width: 66px;
  height: 66px;
  border: 6px solid $secondary-blue;
  border-radius: $radius-half-circle;
  object-fit: cover;
}

.name {
  margin-bottom: 6px;
  font-weight: 700;
  color: #000;
  composes: h5;
}
