@import '../../../../styles/constants.scss';

.content {
  display: flex;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    margin-top: 16px;
  }
}

.formContent {
  width: 100%;
  margin-left: 64px;

  @media screen and (max-width: $breakpoint-medium) {
    margin: 32px 0 0;
  }
}

.overlay {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: $radius-small;
  background: rgba(#fff, 0.9);
  color: #000;
  backdrop-filter: blur(1px);
}

.overlayTitle {
  font-size: 18px;
  font-weight: bold;
}

.overlayFooter {
  margin-top: 36px;
}
