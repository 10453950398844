@import '../../../styles/constants.scss';

.content {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    margin-top: 16px;
  }
}

.details {
  flex: 75%;
  margin-left: 64px;

  @media screen and (max-width: $breakpoint-medium) {
    margin: 32px 0 0;
  }
}

.logo {
  max-width: 160px;
  border-radius: $radius-half-circle;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.row {
  flex: 0 0 30%;
}

.rowTitle {
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.link {
  color: $semantic-link;
}

.emptyValue {
  margin-left: 6px;
  color: $semantic-disabled;
}
