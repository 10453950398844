@import '../../../../../../../../styles/constants.scss';

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.item {
  display: flex;
  flex: 0 0 30%;

  @media screen and (max-width: $breakpoint-large) {
    flex: 0 0 calc(50% - 12px);
  }

  @media screen and (max-width: $breakpoint-medium) {
    flex: 0 0 100%;
  }
}
