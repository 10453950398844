@import '../../../../../../../styles/constants.scss';
@import '../../../../../../../styles/classes/text.module.scss';

.container {
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid $semantic-placeholder;
  border-radius: $radius-small;
  composes: paragraph;
}

.icon {
  flex: 0 0 24px;
  margin-right: 24px;
}
