@import '../../../../styles/constants.scss';
@import '../../../../styles/classes/heading.module.scss';

.attestationBlock {
  padding: 36px;
  border-radius: $radius-small;
  margin: 24px 0;
  background-color: $primary-gray;
}

.switchContainer {
  display: flex;
  align-items: center;
  margin: 24px 0;
}

.switch {
  margin-left: 12px;
}

.highlight {
  color: $primary-blue;
}
