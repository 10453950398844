@import '../../../../../../../../../../../../@components/kit/form/button/styles.module.scss';

.moduleNumber {
  padding-right: 20px;
}

.actionButton {
  padding: 6px 12px;
  border-radius: $radius-xxlarge;
  margin-left: 12px;
  background: $primary-gray;
  transition: 0.3s ease;
  transition-property: background;

  &:enabled:hover,
  &:enabled:focus {
    background: darken($primary-gray, 3%);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
}
