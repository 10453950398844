@import '../../../../../../styles/constants.scss';

.container {
  display: flex;
  align-items: flex-start;
  transition: 0.3s ease-in-out;
  transition-property: opacity;

  &.hidden {
    opacity: 0.4;
  }
}

.column {
  display: flex;
  width: 80%;

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: column;
  }
}

.meta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 20%;
  margin-left: 12px;
}

.pictureContainer {
  flex: 0 0 48px;
  margin-right: 24px;
}

.picture {
  width: 48px;
  height: 48px;
  border-radius: $radius-half-circle;
}

.content {
  padding-top: 6px;
}

.title {
  font-size: 14px;
}

.message {
  position: relative;
  padding-left: 12px;
  margin: 6px 0;
  font-style: italic;
  word-break: break-word;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    border-radius: $radius-xsmall;
    background: $semantic-disabled;
  }
}

.date {
  font-size: 10px;
}

.kudoButton {
  margin: 12px 12px 0 0;

  &:disabled {
    cursor: not-allowed;
  }
}

.kudoButtonIcon {
  width: 24px;
  height: 24px;
}
