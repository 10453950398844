@import '../../../../../../../../../../../styles/constants.scss';

.profilePicture {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  border-radius: $radius-half-circle;
  margin-right: 18px;
  object-fit: cover;
}

.avatarContainer {
  margin-right: 18px;
}

.link {
  text-decoration: none;
  color: #000;
  transition: 0.3s ease;
  transition-property: color;

  &:hover,
  &:focus {
    color: $semantic-link;
  }
}

.tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px 12px;
  border-radius: $radius-xxlarge;
  background: $primary-gray;
}

.emptyValue {
  padding-left: 6px;
}
