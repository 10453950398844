@import '../../../styles/constants.scss';

.container {
  margin-top: 30px;
}

.button {
  width: 30px;
  height: 30px;
  border-radius: $radius-large;
  margin-right: 5px;
  background-color: $primary-gray;

  &:disabled {
    cursor: not-allowed;
  }
}

.pageNumbers {
  margin-right: 5px;
  font-weight: bold;
}
