@import '../constants.scss';

.react-datepicker {
  border: 0;
  box-shadow: 0 2px 10px rgba(#3a3541, 0.3);
  font-family: $secondary-font !important;
}

.react-datepicker__input-container > input {
  width: 100%;
  padding: 14px 12px;
  border: 0 !important;
  border-bottom: 1px solid #000 !important;
  background: transparent;
  font-family: $secondary-font;
  font-size: 14px;
  color: #000;
  transition: 0.3s ease;
  transition-property: background, border-bottom-color;
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }
}
.react-datepicker__day--outside-month {
  color: #757575 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: $primary-blue !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--in-range {
  border-radius: $radius-half-circle;
}

.react-datepicker__day--in-range {
  background: #e4f0ff !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background: #8dc1ff !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: $radius-half-circle !important;
}

.react-datepicker__day--today {
  color: $primary-blue !important;
}

.react-datepicker__day--today.react-datepicker__day--selected {
  color: $primary-blue !important;
}

.react-datepicker__header {
  padding: 12px 0 !important;
  border-bottom: 0;
  background-color: #fff !important;
}

.react-datepicker__navigation {
  top: 12px !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: $primary-blue !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: $primary-blue !important;
}

.react-datepicker__day-names {
  margin-top: 12px;
}

.react-datepicker__day-name {
  font-size: 14px;
}

.react-datepicker__current-month {
  font-size: 16px;
}
