@import '../../../../../styles/constants.scss';

.textarea {
  overflow: hidden;
  width: 100%;
  padding: 14px 12px;
  border: 0;
  border-bottom: 1px solid #000;
  background: transparent;
  font-family: $primary-font;
  font-size: 14px;
  line-height: 1.6;
  color: #000;
  resize: none;

  &::placeholder {
    color: $semantic-placeholder;
  }

  &:focus {
    background: #fafafb;
    outline: none;
  }

  &.error {
    color: $semantic-error;
    border-bottom-color: $semantic-error;

    &::placeholder {
      color: $semantic-error;
    }
  }

  &.disabled,
  &[disabled] {
    color: $semantic-disabled;
    transition: 0.1s ease;
    transition-property: color;
    border-bottom-color: $semantic-disabled;

    &:focus {
      color: #000;
    }
  }
}
