@import '../../../../../../../../styles/constants.scss';

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 36px;

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column-reverse;
  }
}

.column {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-top: 36px;
  @media screen and (max-width: $breakpoint-large) {
    width: 100%;
  }
}

.transcriptColumn {
  width: 30%;
  margin-top: 0;
  background: #fff;
  composes: column;

  @media screen and (max-width: $breakpoint-xlarge) {
    width: 100%;
    padding: 36px 18px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 36px;
  gap: 36px;

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
}

.rowColumn {
  flex: 1 1 50%;
  align-items: stretch;
  height: 100%;

  @media screen and (max-width: $breakpoint-large) {
    flex: 1 1 100%;
    align-items: flex-start;
  }
}

.topicCloudContainer {
  display: flex;
  margin: 36px 0;
}

.column {
  display: flex;
  align-self: stretch;
}
