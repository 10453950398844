.list {
  display: flex;
  gap: 6px;
}

.listItem {
  position: relative;
}

.calendarContainer {
  position: absolute;
  top: 50px;
  right: 0;
}
