.colorSelector {
  width: 60px;
  height: 30px;
  margin: 10px 0 0 30px;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveIcon {
  width: 20px;
  margin-left: 10px;
}
