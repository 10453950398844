@import '../../../../../styles/constants.scss';
@import '../../../../../styles/classes/heading.module.scss';
@import '../../../../../styles/classes/text.module.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 36px;
  border: 0.5px solid $primary-blue;
  border-radius: $radius-medium;
  background-color: #fff;

  @media screen and (max-width: $breakpoint-medium) {
    height: 100%;
    padding: 12px;
  }
}

.question {
  margin-bottom: 36px;
  color: #000;
  composes: h5;
}

.optionsContainer {
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-medium) {
    flex: 1;
    flex-direction: column;
  }
}

.options {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 0 12px;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0;
  }
}

.bandLabel {
  max-width: 120px;
  text-align: center;
  composes: paragraphMedium;

  @media screen and (max-width: $breakpoint-medium) {
    max-width: 100%;
  }
}

.options {
  position: relative;
}

.line {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  margin: 0 auto;
  background: $primary-blue;

  @media screen and (max-width: $breakpoint-medium) {
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
  }
}
