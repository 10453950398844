@import '../../../../styles/constants.scss';
@import '../../../../styles/classes/heading.module.scss';

.container {
  display: flex;
  position: relative;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.heading {
  margin-bottom: 36px;
  color: #fff;
  composes: h1;
}

.title {
  margin-bottom: 8px;
  color: #fff;
  composes: h3;
}

.paragraph {
  margin-bottom: 0;
  color: #fff;
  composes: h4;
}
