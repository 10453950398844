@import '../../../../../../styles/constants.scss';

.icon {
  width: 24px;
  height: 24px;
  color: $primary-blue;
}

.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}
