@import '../../../../../../../../../../../../styles/constants.scss';

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.itemLabel {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.itemValue {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.colorTag {
  display: flex;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 12px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
}

.colorText {
  font-size: 14px;
}

.subList {
  width: 100%;
  margin-left: 20px;
  list-style-type: square;
  column-count: 2;
}

.subListItem {
  margin-bottom: 6px;
}

.itemLink {
  text-decoration: none;
  font-size: 14px;
  color: $semantic-link;
}
