@import '../../../../../styles/constants.scss';

.questionsContainer {
  display: grid;
  width: 100%;
  max-width: 786px;
  padding: 36px 12px 0;
  margin: auto;
  grid-template-columns: 1fr;

  @media screen and (max-width: $breakpoint-medium) {
    flex: 1;
  }
}

.question {
  width: 100%;
  margin: auto;
  grid-column-start: 1;
  grid-row-start: 1;

  @media screen and (max-width: $breakpoint-medium) {
    height: 100%;
  }
}
