@import '../../styles.module.scss';
@import '../../../../../../../../../../styles/constants.scss';

.item {
  display: block;
  position: relative;
  min-width: 250px;
  padding: 24px;
  border-radius: $radius-small;
  background: #fff;
  box-shadow: 0 4px 16px rgba($primary-blue, 0.08);
  text-decoration: none;
  color: $primary-blue;
  transition: 0.3s ease;
  transition-property: background-color, opacity;

  @media screen and (max-width: $breakpoint-xlarge) {
    padding: 18px;
  }
}

.linkItem {
  composes: item;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $secondary-gray3;
  }
}

.highlighted {
  composes: item;
}

.dimmed {
  opacity: 0.5;
  composes: item;
}
