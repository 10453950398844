@import '../../../../../styles/constants.scss';

.container {
  position: relative;
  width: 100%;

  &.error {
    .select {
      color: $semantic-error;
      border-bottom-color: $semantic-error;
    }

    .arrowContainer {
      color: $semantic-error;
    }
  }

  &.borderless {
    .select {
      padding-left: 0;
      border-bottom: 0;

      &:focus {
        background: none;
      }
    }
  }
}

.select {
  width: 100%;
  padding: 14px 40px 14px 12px;
  border: 0;
  border-bottom: 1px solid #000;
  background: transparent;
  text-overflow: ellipsis;
  font-family: $primary-font;
  font-size: 14px;
  color: #000;
  appearance: none;
  cursor: pointer;

  option,
  optgroup {
    font-family: $primary-font;
  }

  &::-ms-expand {
    display: none;
  }

  &:focus {
    background: #fafafb;
    outline: none;
  }

  &:-moz-focusring {
    text-shadow: 0 0 0 #000;
    color: transparent;
  }

  &:disabled {
    color: $semantic-disabled;
    border-bottom-color: $semantic-disabled;
    cursor: not-allowed;

    & + .arrowContainer {
      opacity: 0.5;
    }
  }
}

.arrowContainer {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  height: 100%;
  padding: 20px;
  pointer-events: none;
}

.arrowIcon {
  transform: rotate(-90deg);
  width: 24px;
  height: 24px;
}
