.row {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;

  &:last-child,
  &:last-of-type,
  &.no-margin {
    margin: 0;
  }
}
