@import '../../../../../../../../styles/constants.scss';

.title {
  display: flex;
  align-items: center;
  width: 100%;
}

.highlightedRow {
  position: relative;
  left: 0;
  width: calc(100% + 18px);
  padding: 12px;
  border-radius: $radius-small;
  background: $secondary-light-blue;
}

.inactiveRow {
  opacity: 0.5;
}

.actionButton {
  padding: 6px 12px;
  border-radius: $radius-xxlarge;
  background: $primary-gray;
  font-size: 13px;
  color: $semantic-link;
  transition: 0.3s ease;
  transition-property: background;

  &:enabled:hover,
  &:enabled:focus {
    background: darken($primary-gray, 3%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.actionContent {
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: $breakpoint-medium) {
    justify-content: flex-start;
  }
}
