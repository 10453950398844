@import '../../../../../../../../../../styles/constants.scss';

.message {
  margin: 12px 0 24px;
  font-size: 16px;
}

.row {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.rowKey {
  margin-bottom: 12px;
  font-size: 16px;
}

.rowValue {
  margin-left: 12px;
  font-size: 14px;
}

.recipientList {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  gap: 6px;
}

.recipientItem {
  display: inline-flex;
  align-items: center;
  min-height: 36px;
  padding: 8px 12px;
  border-radius: $radius-xxlarge;
  background: $primary-gray;
  text-align: center;
  font-family: $primary-font;
  font-size: 14px;
  color: #000;
}
