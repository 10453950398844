@import '../../../../../../styles/constants.scss';
@import '../../styles.module.scss';

.boxContainer {
  margin: 6px 0;
  composes: collapseContainer;
}

.checked {
  border-color: $primary-blue;
}

.infoContainer {
  composes: collapseContent;
}

.name {
  composes: collapseHeading;
}

.pill {
  composes: collapsePill;
}

.checkbox {
  input[type='checkbox'] {
    width: 24px;
    height: 24px;
    padding: 0;
    border: 1px solid $primary-blue;
    border-radius: $radius-small;
    cursor: pointer;
  }

  input[type='checkbox']:checked {
    background-color: $primary-blue;
  }

  input[type='checkbox']:checked::after {
    content: url('../../../../../@components/kit/icons/check-white.svg');
    background-color: transparent;
  }

  input[type='checkbox']:disabled {
    border-color: $secondary-gray;
    background-color: $primary-gray;
    cursor: not-allowed;
  }
}

.picture {
  min-width: 66px;
  max-width: 66px;
  min-height: 66px;
  max-height: 66px;
  border: 6px solid $secondary-blue;
  border-radius: $radius-half-circle;
  object-fit: cover;
}
