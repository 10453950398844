@import '../../../../../../../../styles/classes/heading.module.scss';
@import '../../../../../../../../styles/constants.scss';

.container {
  overflow: hidden;
  max-height: 300px;
}

.header {
  padding: 12px;
  border-bottom: 1px solid $primary-gray;
  margin-bottom: 0;
  composes: h5;
}

.content {
  overflow-y: auto;
  max-height: 250px;
  scroll-behavior: smooth;
  scrollbar-color: $primary-blue $primary-gray;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: $primary-gray;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid $primary-gray;
    border-radius: $radius-large;
    background-color: $primary-blue;
  }
}

.footer {
  display: flex;
  justify-content: center;
}

.button {
  width: 100%;
  padding: 24px 0;
  border-bottom: 1px solid transparent;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: $primary-blue;
}

.emptyMessage {
  padding: 12px;
  text-align: center;
  color: $semantic-placeholder;
}

.spinnerContainer {
  padding: 12px 0;
  text-align: center;
}
