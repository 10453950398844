@import '../../../styles/constants.scss';

.avatar {
  display: flex;
  flex: 0 0 60px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: $radius-half-circle;
  background-color: $tertiary-blue;

  &.small {
    flex: 0 0 48px;
    width: 48px;
    height: 48px;
  }

  &.medium {
    flex: 0 0 120px;
    width: 120px;
    height: 120px;
  }

  &.big {
    flex: 0 0 160px;
    width: 160px;
    height: 160px;
  }

  &.dark-blue {
    background-color: $primary-blue;
  }
}

.initials {
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: bold;
  color: $primary-blue;

  .small & {
    font-size: 18px;
  }

  .big & {
    font-size: 32px;
  }

  .dark-blue & {
    color: #fff;
  }
}
