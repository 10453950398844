@import '../../../../../styles/constants.scss';
@import '../../../../../styles/classes/heading.module.scss';

.container {
  display: flex;
  position: relative;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.heading {
  margin-bottom: 36px;
  color: #fff;
  composes: h1;
}

.quoteCitation {
  color: #fff;
  composes: h2;
}

.quoteAuthor {
  margin-bottom: 0;
  color: #fff;
  composes: h3;
}
