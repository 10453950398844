@import '../../../../styles/constants.scss';

.attestationBlock {
  padding: 36px;
  border: 0.5px solid #000;
  border-radius: $radius-small;
  margin: 24px 0;
}

.attestationPreText {
  margin-bottom: 24px;
}

.highlight {
  color: $primary-blue;
}
