@import '../../../../../../../../../../../../styles/constants.scss';

.button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: $primary-gray-dark;
  transition: 0.3s ease;
  transition-property: background-color;

  &:hover,
  &:focus {
    background: $secondary-gray3;
  }

  &.isDisabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.input {
  display: none;

  &:checked {
    + .iconContainer {
      background-color: $primary-blue;

      .icon {
        opacity: 1;
      }
    }
  }
}

.label {
  display: flex;
  align-items: center;
}

.iconContainer {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid $primary-blue;
  border-radius: $radius-small;
  margin-right: 12px;
  background: #fff;
  transition: 0.25s ease;
  transition-property: all;
  cursor: pointer;
}

.icon {
  opacity: 0;
  width: 16px;
  height: 16px;
  color: #fff;
  transition: 0.2s ease;
  transition-property: opacity;
}

.labelText {
  line-height: 1;
}
