@import '../../../../../../../../../../styles/constants.scss';

.container {
  width: 100%;

  &.isDisabled {
    .title {
      color: $semantic-disabled;
    }
  }
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  text-decoration: none;
  line-height: 1;
  transition: 0.3s ease;
  transition-property: background;
  gap: 6px;

  &:hover,
  &:focus {
    background-color: $secondary-gray3;
  }
}

.tag {
  padding: 4px 6px;
  border-radius: $radius-xxlarge;
  background-color: $secondary-light-blue;
}

.title {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  color: $primary-blue;
}
