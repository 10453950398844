@import '../../../../../../../../../../styles/classes/heading.module.scss';

.title {
  margin: 0 18px 0 0;
  color: #000;
  composes: h3;

  @media screen and (max-width: $breakpoint-small) {
    margin: 0 0 18px;
  }
}

.subtitle {
  margin: 6px 0 0;
  composes: h4;
}

.item {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.itemKey {
  font-weight: bold;
}

.itemValue {
  margin-top: 6px;
}

.itemList {
  margin-left: 20px;
  list-style-type: square;
}

.listItem {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.valueList {
  display: grid;
  margin-top: 24px;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: $breakpoint-medium) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.valueItem {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  border: 1px solid $secondary-gray;
  gap: 10px;
  word-break: break-word;
}

.valueItemTitle {
  composes: h5;
}

.hexacoPlot {
  display: flex;
  justify-content: center;
  align-items: center;
}
