@import '../../../../../../../../../../../../styles/constants.scss';

.container {
  padding: 16px 24px;
  border: 1px solid $secondary-gray2;
  font-size: 16px;
}

.title {
  margin-bottom: 10px;
  font-weight: bold;
}

.list {
  margin: 0 0 20px 20px;
  list-style: disc;
}

.listItem {
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}
