@import '../../../../../../styles/constants.scss';

.list {
  margin-top: 6px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid rgba(0, 37, 84, 0.2);
  border-radius: $radius-small;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}

.text {
  margin-right: 12px;
}

.iconContainer {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
}

.icon {
  width: 24px;
  height: 24px;
}
