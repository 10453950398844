@import '../../../../../../styles/constants.scss';
@import '../../../../../@components/kit/form/button/styles.module.scss';

.hexacoNotCompleted {
  font-size: 14px;
  color: $semantic-placeholder;
}

.list {
  display: flex;
  margin-top: 36px;
  gap: 12px;
}

.chart {
  max-width: 560px;
  margin: 0 auto;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.hexacoButton {
  margin-top: 24px;
  text-decoration: none;
  composes: button;
}

.link {
  text-decoration: none;
  composes: secondaryButton;
}
