@import '../../../../styles/constants.scss';

.list {
  position: relative;
  padding: 0;
  margin: 28px 0 0;
  list-style: none;

  &::before {
    display: block;
    content: '';
    position: absolute;
    z-index: 10;
    top: 15px;
    left: 24px;
    height: calc(100% - 18px);
    border-left: 1px solid #000;
  }
}

.item {
  display: flex;
  position: relative;
  align-items: flex-start;
  padding: 15px 12px 12px;
  border-radius: $radius-small;

  &:last-child:not(:only-child) {
    &::before {
      display: block;
      content: '';
      position: absolute;
      z-index: 11;
      top: 15px;
      left: 15px;
      width: 20px;
      height: calc(100% - 15px);
      background: #fff;
    }
  }

  &.completed {
    .itemCheckbox {
      background: #002554;
    }

    .icon {
      display: block;
    }
  }

  &.active {
    background-color: $primary-gray;

    &:last-child:not(:only-child) {
      &::before {
        background: $primary-gray;
      }
    }

    .itemCheckbox {
      background: $primary-gray;
    }
  }
}

.itemCheckbox {
  display: flex;
  position: relative;
  z-index: 12;
  flex: 0 0 24px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid #000;
  border-radius: $radius-half-circle;
  margin-right: 18px;
  background: #fff;
}

.icon {
  display: none;
  width: 16px;
  height: 16px;
  stroke: #fff;
}

.itemText {
  padding-top: 3px;
  font-family: $primary-font;
  font-size: 16px;
  line-height: 1.2;
}
