.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-left: 12px;
}

.icon {
  width: 24px;
  height: 24px;
}
