@import '../../../../../styles/constants.scss';

.editor {
  padding-left: 12px;
  font-size: 14px;

  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global(.DraftEditor-editorContainer) {
    z-index: auto;
  }
}

.editorWrapper {
  border: 1px solid #f1f1f1;

  &.error {
    color: $semantic-error;
    border-bottom-color: $semantic-error;
  }
}

.editorToolbar {
  border: 0;
  border-bottom: 1px solid #f1f1f1;
}
