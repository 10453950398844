@import '../../../../../../../../../../styles/constants.scss';

.list {
  margin-left: 30px;
  list-style: disc;
}

.listItem {
  margin-bottom: 12px;
  color: $primary-blue;

  &:last-child {
    margin-bottom: 0;
  }
}
