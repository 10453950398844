@import '../../../../../../../../styles/classes/heading.module.scss';
@import '../../../../../../../../styles/classes/text.module.scss';

.container {
  text-align: center;
}

.icon {
  width: 50%;
}

.title {
  color: #000;
  composes: h3;
}

.text {
  composes: paragraph;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  gap: 6px;
}
