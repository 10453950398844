@import '../../../styles/constants.scss';
@import '../../../styles/classes/heading.module.scss';

.column {
  flex: 1 1 auto;

  @media screen and (max-width: $breakpoint-large) {
    flex: 0 0 100%;
  }

  &:first-child {
    flex: 0 0 30%;
    margin-right: 36px;

    @media screen and (max-width: $breakpoint-large) {
      margin: 0 0 36px;
    }
  }
}
