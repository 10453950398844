@import '../../../../../../styles/constants.scss';

.item {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: $breakpoint-xlarge) {
    flex-direction: column;
  }

  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;

    textarea {
      cursor: not-allowed;
    }
  }
}

.tag {
  min-width: 90px;
  margin-right: 36px;

  @media screen and (max-width: $breakpoint-xlarge) {
    margin: 0 0 12px;
  }
}

.content {
  flex: 1 1 100%;

  @media screen and (max-width: $breakpoint-xlarge) {
    width: 100%;
  }
}
