@import '../../../@components/kit/form/button/styles.module.scss';
@import '../../../../styles/constants.scss';
@import '../../../../styles/classes/text.module.scss';

.header {
  display: flex;
  position: absolute;
  z-index: 5;
  top: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 36px;

  @media screen and (max-width: $breakpoint-medium) {
    position: sticky;
    padding: 12px;
    background-color: #fff;
  }
}

.logo {
  width: 90px;
}

.backLink {
  text-decoration: none;
  composes: secondaryButton;
}

.content {
  display: flex;
}

.progressSaved {
  display: flex;
  align-items: center;
  margin-right: 24px;
  color: $semantic-success;
  composes: paragraphMedium;
}

.icon {
  width: 24px;
  height: 24px;
  margin-left: 6px;
}
