@import '../../../../../styles/constants.scss';
@import '../../../../../styles/classes/text.module.scss';

.dropzone {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 121px;
  height: 121px;
  border: 0.5px solid $secondary-gray;
  border-radius: $radius-xsmall;
  cursor: pointer;
}

.label {
  color: $primary-blue;
  composes: paragraphMedium;
}

.icon {
  width: 27px;
  height: 27px;
  margin-bottom: 6px;
  color: $primary-blue;
}

.editIcon {
  position: absolute;
  right: -15px;
  bottom: 0;
  flex: 0 0 24px;
  width: 36px;
  height: 36px;
  padding: 8px;
  border-radius: $radius-half-circle;
  background: $primary-blue;
  color: #fff;
  stroke-width: 2px;
}

.imageContainer {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  border: 1px solid $secondary-gray;
}
