@import '../../../../styles/constants.scss';

.signatureBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.presetContainer {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  gap: 18px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.padContainer {
  position: relative;
  width: 100%;
  border: 0.5px solid $secondary-gray;
  border-radius: $radius-small;
  margin-top: 24px;

  canvas {
    width: 100% !important;
    height: 92px !important;
  }
}

.presetWrapper {
  border: 0.5px solid $secondary-gray;
  border-radius: $radius-small;
}

.active {
  border: 0.5px solid #000;
}
