@import '../../../../styles/constants.scss';

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
  }
}

.organizationStructureContainer {
  display: flex;
  margin-top: 36px;

  .column {
    flex: 0 0 calc(50% - 18px);
    margin-left: 0 !important;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin-top: 24px;

    .column {
      flex: 0 0 100%;
      margin-top: 0 !important;
    }
  }
}

.column {
  flex: 1 0 calc(50% - 18px);
  margin-right: 18px;

  &:last-child {
    margin-left: 18px;
  }

  @media screen and (max-width: $breakpoint-large) {
    flex: 1 1 auto;
    margin: 0;

    &:last-child {
      margin: 24px 0 0;
    }
  }
}

.box {
  padding: 36px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-medium;

  & + .box {
    margin-top: 36px;
  }

  @media screen and (max-width: $breakpoint-large) {
    padding: 24px;

    & + .box {
      margin-top: 24px;
    }
  }
}

.editBox {
  .boxItem {
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
    margin-bottom: 24px;
    gap: 6px;
  }

  .boxItemValue {
    width: 100%;
    max-width: none;
  }
}

.workStylePreferences {
  composes: box;

  .boxItemKey {
    min-width: 200px;
  }

  @media screen and (max-width: $breakpoint-xlarge) {
    .boxItemSublistItem {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: $breakpoint-xxlarge) {
    .boxItem {
      flex-direction: column;
      align-items: flex-start;
    }

    .boxItemList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px;
    }

    .boxItemSublistItem {
      margin-bottom: 0;
    }

    .boxItemValue {
      flex-direction: column;
      max-width: 100%;
    }
  }
}

.boxItem {
  display: flex;
  align-items: baseline;
  margin-bottom: 12px;
  gap: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: $breakpoint-xlarge) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 16px;
    gap: 12px;
  }
}

.boxItemDisabled {
  color: $semantic-disabled;
  composes: boxItem;
  cursor: not-allowed;

  [disabled] {
    cursor: not-allowed;
  }
}

.boxTitle {
  margin-bottom: 36px;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.boxItemKey {
  align-self: center;
  min-width: 130px;
  font-size: 16px;

  @media screen and (max-width: $breakpoint-xlarge) {
    width: 100%;
    min-width: auto;
  }
}

.boxItemValue {
  display: flex;
  flex-wrap: wrap;
  max-width: 250px;
  gap: 6px;

  @media screen and (max-width: $breakpoint-xlarge) {
    max-width: none;
  }
}

.emptyValue {
  margin-left: 6px;
  color: $semantic-disabled;
}

.boxItemIcon {
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.boxItemSublistItem {
  display: flex;
  margin-bottom: 12px;
  gap: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}
