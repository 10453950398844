@import '../../../../../styles/constants.scss';
@import '../../../../@components/kit/form/form-group/styles.module.scss';

.formContainer {
  margin-top: 24px;
}

.formGroup {
  max-width: 390px;
  composes: formGroup;
}
