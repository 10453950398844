@import '../../../../styles/constants.scss';

.feedback {
  width: 400px;
  padding: 40px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  background-color: #fff;
  text-align: center;
}
