@import '../../../../../../../../../../../styles/classes/heading.module.scss';

.title {
  margin: 0 18px 0 0;
  color: #000;
  composes: h3;

  @media screen and (max-width: $breakpoint-small) {
    margin: 0 0 18px;
  }
}

.subtitle {
  margin: 6px 0 0;
  color: #000;
  composes: h4;
}

.contentTitle {
  margin-bottom: 12px;
}

.description {
  margin-bottom: 24px;
}
