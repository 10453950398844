@import '../../../../../../styles/constants.scss';
@import '../../../../../../styles/classes/heading.module.scss';
@import '../../../../../../styles/classes/text.module.scss';

.link {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 24px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  text-align: left;
  text-decoration: none;
  transition: 0.3s ease;
  transition-property: background;

  &:hover,
  &:focus {
    background: $secondary-gray3;
  }
}

.header {
  display: flex;
  align-items: center;
}

.title {
  composes: h5;
}

.content {
  margin-top: 36px;
  color: $primary-blue;
  composes: paragraphMedium;
}
