@import '../../../../../../styles/classes/heading.module.scss';
@import '../../../../../../styles/classes/text.module.scss';
@import '../../../../../../styles/constants.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 18px;
  text-align: center;
}

.title {
  composes: h4;
}

.text {
  composes: paragraphMedium;
}
