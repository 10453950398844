@import '../../../../styles/classes/heading.module.scss';

.headerText {
  margin-bottom: 36px;
}

.logo {
  width: 100px;
}

.text {
  margin-bottom: 36px;
  color: #fff;
  composes: h2;
}

.link {
  display: inline-block;
  padding: 16px 24px;
  border: 1px solid #63f4f9;
  border-radius: $radius-medium;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  transition: 0.3s ease;
  transition-property: border-color;

  &:hover,
  &:focus {
    border-color: #08a6f7;
  }
}
