@import '../../../../styles/classes/heading.module.scss';

.header {
  padding-bottom: 120px;
  background: #002554 url('./assets/bg-header-swoosh.png') no-repeat top left;
  background-size: cover;

  &.small {
    padding-bottom: 90px;
  }

  &.xsmall {
    padding-bottom: 45px;
  }

  @media screen and (max-width: $breakpoint-xlarge) {
    padding-bottom: 108px;
  }

  @media screen and (max-width: $breakpoint-large) {
    padding-bottom: 96px;
  }
}

.wrapper {
  position: relative;
  max-width: 1400px;
  padding: 0 20px 0 60px;
  margin: 0 auto;

  @media screen and (max-width: $breakpoint-xxlarge) {
    padding-right: 24px;
    padding-left: 24px;
  }

  @media screen and (max-width: $breakpoint-medium) {
    padding: 0 12px;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding-top: 20px;
  }
}

.logo {
  width: 80px;
}

.metaHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  @media screen and (max-width: $breakpoint-small) {
    display: none;
  }
}

.button {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #fff;
}

.userName {
  @media screen and (max-width: $breakpoint-medium) {
    display: none;
  }
}

.userAvatar {
  width: 40px;
  height: 40px;
  border-radius: $radius-half-circle;
  margin-right: 12px;
  object-fit: cover;
}

.userNavigationContainer {
  position: absolute;
  z-index: 10;
  top: 48px;
  right: 20px;
  width: 225px;
  padding: 6px 12px;
  border: 0.5px solid $secondary-gray2;
  border-radius: $radius-small;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(#000, 0.3);
}

.title {
  margin-bottom: 0;
  color: #fff;
  composes: h1;

  .surtitle {
    font-weight: 400;
  }
}

.yieldContainer {
  margin-top: 48px;

  @media screen and (max-width: $breakpoint-large) {
    margin-top: 24px;
  }
}
