@import '../../../../../../../../../../styles/constants.scss';

.item {
  padding: 12px;
  border-bottom: 1px solid $primary-gray;
  font-size: 13px;
  transition: 0.3s ease;
  transition-property: border-bottom-color, background;

  &.isUnread {
    background: $secondary-gray3;
    border-bottom-color: $primary-gray;
  }
}

.date {
  display: block;
  margin-top: 4px;
  font-size: 11px;
  color: $primary-gray-dark;
}
