@import '../../../../../../styles/constants.scss';

.table {
  width: 100%;
  font-family: $primary-font;
}

.tableHead {
  margin-bottom: 12px;

  @media screen and (max-width: $breakpoint-medium) {
    margin-bottom: 6px;
  }

  .tableData {
    border-radius: $radius-small;
    background: $secondary-gray3;
    font-size: 10px;

    &:first-child {
      background: none;
    }

    @media screen and (max-width: $breakpoint-medium) {
      padding: 12px;
    }
  }
}

.tableRow {
  display: flex;
  align-items: center;
}

.tableBody {
  .tableData:first-child {
    padding: 12px;
    font-family: $primary-font;
    font-size: 12px;
  }
}

.tableData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 12px 6px;
  margin: 0 6px;
  font-size: 14px;
  line-height: 1;

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 2px;
  }

  &:first-of-type {
    justify-content: flex-start;
    width: 40%;
    margin: 0;
    line-height: 1.4;

    @media screen and (max-width: $breakpoint-medium) {
      width: 30%;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

.tableSeparator {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px;
  border-radius: $radius-small;
  margin: 6px 0;
  background: $secondary-gray3;

  @media screen and (max-width: $breakpoint-medium) {
    margin-top: 0;
  }

  .icon {
    margin-right: 12px;

    @media screen and (max-width: $breakpoint-medium) {
      display: none;
    }
  }
}

.icon {
  width: 24px;
  height: 24px;
}
