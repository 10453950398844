@import '../../../../../../../../../styles/constants.scss';

.tableHeader {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 1px solid #000;
  font-size: 14px;
  font-weight: 400;

  @media screen and (max-width: $breakpoint-medium) {
    display: none;
  }
}

.tableData {
  @media screen and (max-width: $breakpoint-medium) {
    display: flex;
    align-items: center;
    width: 100% !important;
    margin: 0 0 12px !important;
  }

  @media screen and (max-width: $breakpoint-small) {
    display: block;
  }
}

.occurenceColumn {
  display: flex;
  align-items: center;
  width: 25%;
  margin-right: 6px;
}

.dueDateColumn,
.dateInitiatedColumn {
  width: 15%;
  margin-right: 6px;
}

.populationColumn {
  width: 25%;
  margin-right: 6px;
}

.actionsColumn {
  width: 20%;
}

.content {
  margin-top: 12px;
}
