@import '../constants.scss';

.splide__pagination {
  position: relative;
  max-width: 100%;
  margin-top: 24px;
}

.splide__track--slide {
  padding-bottom: 25px;
}

.splide__pagination__page {
  background: $secondary-gray;

  &.is-active {
    opacity: 1;
    transform: scale(1, 1);
    background-color: $primary-blue;
  }
}
