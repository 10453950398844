@import '../../../../../styles/classes/heading.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 36px 24px;

  @media screen and (max-width: $breakpoint-medium) {
    padding: 18px;
  }
}

.content {
  width: 100%;
}

.title {
  margin-bottom: 0;
  color: #000;
  composes: h3;
}
