@import '../../../../../styles/constants.scss';

.container.error {
  .labelText {
    color: $semantic-error;
  }
}

.input {
  display: none;

  &:checked {
    + .label {
      .iconContainer {
        background-color: $primary-blue;
      }

      .icon {
        opacity: 1;
      }
    }
  }

  &:disabled {
    + .label {
      opacity: 0.5;
      cursor: not-allowed;

      .iconContainer {
        border-color: $semantic-disabled;
        background-color: $semantic-disabled;
      }
    }
  }
}

.label {
  display: flex;
  align-items: center;
}

.iconContainer {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid $primary-blue;
  border-radius: $radius-small;
  margin-right: 12px;
  background: #fff;
  transition: 0.25s ease;
  transition-property: all;
  cursor: pointer;
}

.icon {
  opacity: 0;
  width: 16px;
  height: 16px;
  color: #fff;
  transition: 0.2s ease;
  transition-property: opacity;
}

.labelText {
  line-height: 1;
}
