@import '../../../../../../styles/constants.scss';

.item {
  border-bottom: 1px solid $secondary-gray2;

  &:last-of-type {
    border-bottom: 0;
  }
}

.link {
  display: flex;
  position: relative;
  align-items: center;
  padding: 12px;
  text-decoration: none;
  font-family: $primary-font;
  font-size: 14px;
  color: $primary-blue;

  &:hover {
    .icon {
      margin-right: 18px;
    }
  }
}

.text {
  line-height: 1;
}

.icon {
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
  margin-right: 12px;
  transition: 0.3s ease;
  transition-property: margin-right;
}
