@import '../../../../../../styles/constants.scss';

.link {
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;
  text-decoration: none;
  font-size: 12px;
  color: $primary-blue;

  &:hover,
  &:focus {
    .icon {
      margin-right: 12px;
    }
  }
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  transition: 0.3s ease;
  transition-property: margin-right;
}
