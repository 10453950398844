@import '../../../../styles/classes/heading.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  height: 60px;
  padding: 6px;
  background: #002554;

  @media screen and (min-width: $breakpoint-small) {
    display: none;
  }
}

.burgerButton {
  position: relative;
  z-index: 10;
  width: 48px;
  height: 48px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &.active {
    .burgerButtonLines {
      opacity: 0;
    }

    .burgerButtonLines:first-child {
      opacity: 1;
      transform: rotate(40deg);
    }

    .burgerButtonLines:last-child {
      opacity: 1;
      transform: rotate(-40deg);
    }
  }
}

.burgerButtonLines {
  position: absolute;
  top: 50%;
  left: 25%;
  width: 20px;
  height: 2px;
  background-color: #fff;
  transition: all 400ms cubic-bezier(0.84, 0.06, 0.52, 1.8);

  &:first-child {
    transform: translateY(-6px);
    animation-delay: 100ms;
  }

  &:last-child {
    transform: translateY(6px);
    animation-delay: 250ms;
  }
}

.logo {
  width: 80px;
}

.headerColumn {
  display: flex;
  flex: 0 0 33%;
  align-items: center;

  &:nth-child(2) {
    justify-content: center;
  }

  &:last-child {
    justify-content: flex-end;
  }
}

.userAvatar {
  width: 40px;
  height: 40px;
  border-radius: $radius-half-circle;
}

.visibleOnMobile {
  display: none;

  @media screen and (max-width: $breakpoint-small) {
    display: block;
  }
}
