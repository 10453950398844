@import '../../../../../styles/classes/heading.module.scss';
@import '../../../../../styles/classes/text.module.scss';
@import '../../../../../styles/constants.scss';

.summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40vw;
  min-height: 100vh;
  padding: 120px 72px 36px;
  background: $primary-gray;

  @media screen and (max-width: $breakpoint-medium) {
    width: 100vw;
    min-height: auto;
    padding: 36px 24px;
  }
}

.title {
  margin-bottom: 36px;
  color: #000;
  composes: h1;
}

.tagList {
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.tagListItem {
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: $breakpoint-large) {
    margin: 0 0 12px;

    &:last-child {
      margin: 0;
    }
  }
}

.description {
  margin: 36px 0;
  composes: paragraph;
}

.noteListItem {
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}
