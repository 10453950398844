@import '../../../../styles/constants.scss';
@import '../../../../styles/classes/heading.module.scss';

.quote {
  padding: 36px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  margin: 0 96px;
  background-color: #fff;
}

.quoteCitation {
  composes: h2;
}

.quoteAuthor {
  margin-bottom: 0;
  composes: h3;
}
