@import '../constants.scss';

.h1 {
  margin: 0 0 48px;
  font-family: $secondary-font;
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  color: $primary-blue;

  @media screen and (max-width: $breakpoint-large) {
    margin-bottom: 36px;
    font-size: 32px;
  }
}

.h2 {
  margin: 0 0 36px;
  font-family: $secondary-font;
  font-size: 36px;
  font-weight: 400;
  line-height: 1;
  color: $primary-blue;

  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }
}

.h3 {
  margin: 0 0 24px;
  font-family: $secondary-font;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: $primary-blue;
}

.h4 {
  margin: 0 0 12px;
  font-family: $secondary-font;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  color: $primary-blue;
}

.h5 {
  margin: 0 0 12px;
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: $primary-blue;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
  }
}
