@import '../../../../../../../../../../styles/classes/heading.module.scss';

.title {
  margin: 0 18px 0 0;
  color: #000;
  composes: h3;

  @media screen and (max-width: $breakpoint-small) {
    margin: 0 0 18px;
  }
}

.subtitle {
  margin: 6px 0 0;
  composes: h4;
}

.calendarContainer {
  display: flex;
  position: relative;
}

.calendarIcon {
  position: absolute;
  top: 12px;
  right: 6px;
  width: 24px;
  height: 24px;
  pointer-events: none;
}
