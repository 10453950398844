@import '../styles.module.scss';

.sendEmailContainer {
  display: flex;
  justify-content: center;
  height: 24px;
}

.getEmailText {
  margin-top: 0;
  composes: text;
}

.sendEmailLink {
  margin-left: 20px;
  text-decoration: none;
  color: $primary-blue;
  composes: getEmailText;
}

.arrow {
  align-self: flex-end;
  width: 24px;
  height: 24px;
}
