@import '../../../../../styles/classes/text.module.scss';

.column {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.headerButton {
  margin-left: 12px;
}

.headerButtonIcon {
  width: 24px;
  height: 24px;
}

.text {
  composes: paragraph;
}

.placeholderText {
  font-style: italic;
  color: $secondary-gray;
}
