@import '../../../../../../styles/constants.scss';
@import '../../../../../../styles/classes/heading.module.scss';

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.heading {
  margin-bottom: 12px;
  color: #000;
  composes: h3;
}

.list {
  display: grid;
  margin-top: 24px;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: $breakpoint-medium) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.item {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  border: 1px solid $secondary-gray;
  gap: 10px;
  word-break: break-word;
}

.valueTitle {
  composes: h5;
}
