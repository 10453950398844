@import '../../../../styles/constants.scss';

.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: url('../../../../assets/images/bg-swoosh.png') no-repeat bottom
    left;
  background-size: 100%;

  @media screen and (max-width: $breakpoint-medium) {
    min-height: auto;
  }
}

.container {
  display: flex;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
  }
}

.column {
  display: flex;
  flex: 1 1 100%;

  @media screen and (max-width: $breakpoint-medium) {
    width: 100vw;
    min-height: auto;
    padding: 24px 12px;
  }
}

.sidebar {
  flex: 1 1 45vw;
  flex-direction: column;
  align-items: flex-start;
  max-width: 582px;
  padding: 204px 48px 36px;
  background: $primary-gray;
  composes: column;

  @media screen and (max-width: $breakpoint-medium) {
    flex: 0 0 auto;
    max-width: 100%;
    min-height: auto;
    padding: 36px 24px;
  }
}

.contentWrapper {
  flex-direction: column;
  padding: 132px 36px 36px;
  composes: column;

  @media screen and (max-width: $breakpoint-medium) {
    justify-content: flex-start;
    padding: 36px;
  }
}

.content {
  width: 100%;
  max-width: 786px;
  padding: 36px;
  border: 1px solid #000;
  border-radius: $radius-small;
  margin: 0 auto;
  margin-bottom: 64px;
  background: #fff;
}
