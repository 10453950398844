@font-face {
  font-family: 'TT Interfaces';
  font-weight: 400;
  src: local('TT Interfaces Regular'), local('TT-Interfaces-Regular'),
    url('./TTInterfaces-Regular.woff2') format('woff2'),
    url('./TTInterfaces-Regular.woff') format('woff'),
    url('./TTInterfaces-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'TT Interfaces';
  font-weight: 700;
  src: url('./TTInterfaces-Bold.woff2') format('woff2'),
    url('./TTInterfaces-Bold.woff') format('woff'),
    url('./TTInterfaces-Bold.ttf') format('truetype');
}
