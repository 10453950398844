@import '../../../../../../styles/constants.scss';
@import '../../../../../@components/kit/form/button/styles.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.column {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $breakpoint-medium) {
    &:last-child:not(:only-child) {
      margin-top: 36px;
    }
  }
}

.profileContainer {
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-medium) {
    align-items: flex-start;
  }
}

.profile {
  display: flex;
  flex-direction: column;
}

.pictureContainer {
  flex: 0 0 120px;
  width: 120px;
  height: 120px;
  margin-right: 24px;

  @media screen and (max-width: $breakpoint-medium) {
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
    margin: 12px 12px 0 0;
  }
}

.picture {
  width: 100%;
  height: 100%;
  border-radius: $radius-half-circle;
  object-fit: cover;
}

.name {
  font-family: $secondary-font;
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  color: #000;

  @media screen and (max-width: $breakpoint-medium) {
    font-size: 32px;
  }
}

.jobTitle {
  margin-top: 16px;
}

.actions {
  display: flex;
  align-items: center;
}

.actionItem {
  margin-right: 12px;

  &:last-child {
    margin-right: 0;
  }
}

.link {
  text-decoration: none;
  composes: secondaryButton;
}
