@import '../../../../../../../../../../styles/constants.scss';
@import '../../../../../../../../../../styles/classes/table.module.scss';
@import '../../../../../../../../../../styles/constants.scss';

.item {
  margin-bottom: 12px;

  @media screen and (max-width: $breakpoint-medium) {
    border-bottom: 1px solid $secondary-gray;
    margin-bottom: 24px;
  }
}

.row {
  display: flex;
  align-items: center;
  font-size: 14px;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.disabledRow {
  color: $semantic-disabled;
  composes: row;
}

.actionButton {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: $radius-xxlarge;
  background: $primary-gray;
  font-size: 13px;
  color: $semantic-link;
  transition: 0.3s ease;
  transition-property: background;

  &:enabled:hover,
  &:enabled:focus {
    background: darken($primary-gray, 3%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.actionButtonIcon {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.alignmentScoreContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.centeredContent {
  width: 100%;
  text-align: center;

  @media screen and (max-width: $breakpoint-medium) {
    text-align: left;
  }
}

.actionContent {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: $breakpoint-medium) {
    justify-content: flex-start;
  }
}
