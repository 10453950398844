@import '../../../styles/constants.scss';
@import '../../../styles/classes/text.module.scss';

.container {
  position: relative;
}

.scale {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 32px auto;

  &::after {
    display: block;
    content: '';
    position: absolute;
    left: 5%;
    width: 90%;
    height: 1px;
    background-color: $secondary-gray;
  }
}

.scaleLabel {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  composes: paragraphMedium;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: -5px;
    width: 10px;
    height: 10px;
    border: 1px solid gray;
    border-radius: $radius-full-circle;
    margin-bottom: 20px;
    background-color: white;
  }
}

.score {
  display: flex;
  position: absolute;
  z-index: 2;
  top: -11px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: $radius-full-circle;
  background: #000;
  text-align: center;
  color: #fff;
}

.icon {
  width: 16px;
  height: 16px;
  stroke-width: 2;
}
