@import '../../../../../../../../../../styles/constants.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  padding: 12px 0;
  border-radius: $radius-medium;
  background: $secondary-gray3;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: rgba(#000, 0.7);
  transition: 0.3s ease;
  transition-property: color, background;

  &:hover,
  &:focus {
    background: $secondary-gray2;
  }

  &.selected {
    background: $secondary-light-blue;
    color: #000;
  }
}
