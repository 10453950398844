@import '../../../../styles/constants.scss';

.formHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;

  @media screen and (max-width: $breakpoint-xlarge) {
    flex-direction: column;
  }
}

.dialogButton {
  font-family: $primary-font;
  font-size: 14px;
  color: $primary-blue;

  @media screen and (max-width: $breakpoint-xlarge) {
    margin-top: 12px;
  }
}

.formContent {
  margin-top: 24px;
}

.formFooter {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.roleItem {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}
