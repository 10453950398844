.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }
}
