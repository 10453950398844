@import '../../../styles/constants.scss';

.container {
  display: inline-flex;
  align-items: center;
  border-radius: 100px;
  text-align: center;
  font-family: $primary-font;
  font-size: 14px;
  color: #000;
}

.color {
  width: 16px;
  height: 16px;
  border-radius: 100px;
}

.name {
  margin-left: 10px;
}
