@import '../../../../../styles/constants.scss';

.list {
  &.error {
    .item {
      border-color: $semantic-error;
    }
  }
}

.item {
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  margin-bottom: 6px;
  transition: 0.3s ease;
  transition-property: background;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:focus {
    background: $secondary-gray3;
  }
}
