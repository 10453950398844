@import '../../../styles/classes/heading.module.scss';
@import '../../../styles/classes/text.module.scss';
@import '../../../styles/constants.scss';

.mainContainer {
  flex: 1 1 auto;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.headerTitle {
  margin-bottom: 0;
  color: #000;
  composes: h3;
}

.headerIcon {
  width: 24px;
  height: 24px;
  margin-left: 12px;
  stroke-width: 2px;
}

.description {
  color: #000;
  composes: paragraph;
}

.line {
  height: 1px;
  margin: 24px 0;
  background-color: $secondary-gray;
}

.sidebar {
  display: flex;
  flex: 0 0 420px;
  flex-direction: column;
  border-radius: $radius-small;
  margin: 0 0 36px 36px;
  background-color: #fff;

  @media screen and (max-width: $breakpoint-xxlarge) {
    margin-left: 24px;
    flex-basis: 325px;
  }

  @media screen and (max-width: $breakpoint-xlarge) {
    flex-basis: 300px;
  }

  @media screen and (max-width: $breakpoint-large) {
    width: 100%;
    margin: 12px 0 36px;
  }
}

.sidebarRow {
  padding: 36px;

  @media screen and (max-width: $breakpoint-xlarge) {
    padding: 18px;
  }

  & + .sidebarRow {
    margin-bottom: 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
