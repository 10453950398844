@import '../../../../styles/classes/text.module.scss';
@import '../../../../styles/constants.scss';
@import '../../../@components/kit/form/form-group/styles.module.scss';
@import '../../../@components/kit/form/label/styles.module.scss';
@import '../../../@components/tag/styles.module.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  font-family: $secondary-font;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;
}

.titleIcon {
  width: 24px;
  height: 24px;
  margin-left: 6px;
}

.description {
  margin: 12px 0 24px;
  composes: paragraph;
}

.formContainer {
  display: flex;
  align-content: stretch;
}

.theForm {
  display: none;
  flex: 1 1 auto;
  padding: 36px;
}

.skipButton {
  padding: 16px 24px;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: $primary-blue;
}
