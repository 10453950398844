@import '../../../../../../styles/constants.scss';

.container {
  position: absolute;
  z-index: 12;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  padding: 6px;
  background: #fff;
  scroll-behavior: smooth;
}
