@import '../../../../../../../../../../../styles/constants.scss';
@import '../../../../../../../../../../@components/kit/form/button/styles.module.scss';

.item {
  margin-bottom: 12px;
}

.row {
  display: flex;
  align-items: center;
  font-size: 14px;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.emptyValue {
  display: inline-flex;
  margin: 0 6px;
}

.toggleContainer {
  margin-right: 12px;

  @media screen and (max-width: $breakpoint-small) {
    margin-bottom: 12px;
  }
}

.actionButton {
  padding: 6px 12px;
  border-radius: $radius-xxlarge;
  background: $primary-gray;
  font-size: 13px;
  color: $semantic-link;
  transition: 0.3s ease;
  transition-property: background;

  &:enabled:hover,
  &:enabled:focus {
    background: darken($primary-gray, 3%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.actionLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  composes: actionButton;

  &:hover,
  &:focus {
    background: darken($primary-gray, 3%);
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.buttonIcon {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.pillWidth {
  width: 120px;

  @media screen and (max-width: $breakpoint-xlarge) {
    width: 90px;
  }
}
