@import '../../../styles/constants.scss';

.box {
  position: relative;
  width: 100%;
  padding: 36px;
  border-radius: $radius-small;
  background: #fff;
  box-shadow: 0 4px 16px rgba($primary-blue, 0.08);

  & + .box {
    margin-top: 36px;
  }

  &.half {
    width: 48%;
    margin-top: 0;

    @media screen and (max-width: $breakpoint-medium) {
      width: 100%;
      margin-top: 36px;
    }
  }

  &.with-border {
    border: 1px solid $primary-blue;
  }

  &.spacing-small {
    padding: 24px;
  }

  &.spacing-xsmall {
    padding: 16px;

    @media screen and (max-width: $breakpoint-xlarge) {
      padding: 12px;
    }
  }

  &.full-height {
    height: 100%;
  }

  @media screen and (max-width: $breakpoint-xlarge) {
    padding: 18px;
  }
}
