@import '../../../../../styles/constants.scss';
@import '../../../../../styles/classes/heading.module.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 36px;
  border: 0.5px solid $primary-blue;
  border-radius: $radius-medium;
  background-color: #fff;

  @media screen and (max-width: $breakpoint-medium) {
    height: 100%;
    padding: 12px;
  }
}

.question {
  margin-bottom: 36px;
  color: #000;
  composes: h5;
}

.checkboxContainer {
  display: grid;
  margin-bottom: 24px;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: $breakpoint-medium) {
    grid-template-columns: repeat(2, 1fr);
  }
}
