@import '../../../../../styles/constants.scss';

.formGrid {
  display: grid;
  margin-top: 24px;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: $breakpoint-small) {
    display: flex;
    flex-direction: column;
  }
}

.profilePictureContainer {
  display: flex;
  flex-direction: column;
  grid-area: 1 / 1 / 2 / 2;

  @media screen and (max-width: $breakpoint-small) {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.nameContainer {
  grid-area: 2 / 1 / 3 / 2;

  @media screen and (max-width: $breakpoint-small) {
    grid-area: 2 / 1 / 3 / 2;
  }
}

.preferredNameContainer {
  grid-area: 2 / 2 / 3 / 3;

  @media screen and (max-width: $breakpoint-small) {
    grid-area: 3 / 1 / 4 / 2;
  }
}

.workEmailContainer {
  grid-area: 3 / 1 / 4 / 2;

  @media screen and (max-width: $breakpoint-small) {
    grid-area: 4 / 1 / 5 / 2;
  }
}

.personalEmailContainer {
  grid-area: 3 / 2 / 4 / 3;

  @media screen and (max-width: $breakpoint-small) {
    grid-area: 5 / 1 / 6 / 2;
  }
}

.phoneNumberContainer {
  grid-area: 4 / 1 / 5 / 2;

  @media screen and (max-width: $breakpoint-small) {
    grid-area: 6 / 1 / 7 / 2;
  }
}

.jobTitleContainer {
  grid-area: 5 / 1 / 5 / 2;

  @media screen and (max-width: $breakpoint-small) {
    grid-area: 7 / 1 / 7 / 2;
  }
}

.timeZoneContainer {
  grid-area: 6 / 1 / 6 / 2;

  @media screen and (max-width: $breakpoint-small) {
    grid-area: 8 / 1 / 8 / 2;
  }
}
