@import '../../../../../../styles/constants.scss';

.item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: $breakpoint-xlarge) {
    flex-direction: column;
  }
}

.column {
  &:first-child {
    flex: 0 0 165px;
    margin-right: 36px;
  }

  @media screen and (max-width: $breakpoint-xlarge) {
    flex: 0 0 100%;

    &:first-child {
      flex: 0 0 100%;
      margin: 0 0 12px;
    }
  }
}

.sublistItem {
  display: block;
  max-width: 400px;
  padding: 12px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}

.behaviorlist {
  width: 100%;
  float: left;
}
