@import '../../../../../../../../../../../styles/constants.scss';

.link {
  padding: 6px 12px;
  border-radius: $radius-xxlarge;
  background: $primary-gray;
  text-decoration: none;
  font-size: 13px;
  color: $semantic-link;
  transition: 0.3s ease;
  transition-property: background;

  &:enabled:hover,
  &:enabled:focus {
    background: darken($primary-gray, 3%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.tooltipContainer {
  max-width: 400px;
}
