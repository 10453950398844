.label {
  display: inline-flex;
  align-items: center;
  padding-bottom: 12px;
  font-size: 16px;
  transition: 0.3s ease;
  transition-property: color;
}

.error {
  color: #8e1818;

  .labelIcon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
}

.withoutIcon {
  min-height: 36px;
}

.labelIcon {
  width: 0;
  height: 0;
  transition: 0.3s ease;
  transition-property: width;
}
