@import '../../../../../../../styles/constants.scss';
@import '../../../../../../../styles/classes/text.module.scss';

.buttonIcon {
  width: 18px;
  height: 18px;
  margin-left: 12px;
}

.button {
  display: block;
  width: 100%;
  padding: 24px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  text-align: left;
  transition: 0.3s ease;
  transition-property: background;

  &:hover,
  &:focus {
    background: $secondary-gray3;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 36px;
  composes: paragraph;
}

.headerIcon {
  width: 24px;
  height: 24px;
  margin-left: 6px;
}

.footer {
  display: flex;
  flex-direction: column;
  composes: paragraphSmall;
}

.link {
  text-decoration: none;
  color: $primary-blue;
}
