@import '../../../../../../../../styles/constants.scss';

.icon {
  width: 20px;
  height: 20px;
  fill: $primary-blue;
}

.iconContainer {
  width: 20px;
  margin-right: 12px;
}
