@import '../../../styles/constants.scss';

.audioPlayer {
  width: 100%;
  border-radius: $radius-small;
  background-color: #f1f3f4;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global(.my-class) {
    background-color: $specific-score-neutral;
  }
}

.roundCard {
  padding: 20px;
  border-radius: $radius-small;
  background: #fff;
}

.transcriptionContainer {
  composes: roundCard;
}

.mainContainer {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
  }
}

.participantsContainer {
  min-width: 31%;
  height: fit-content;
  margin-left: 5px;
  composes: roundCard;

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
    margin-top: 30px;
  }
}

.textWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  line-height: 1.6;
}

.interventionSpeaker {
  width: 100%;
  margin: 15px 0 -5px;
  background-color: transparent !important; // this is so it doesnt get the highlighted color
  font-size: 20px;
  font-weight: bold;
  color: $primary-blue;
}

.interventionTimestamp {
  margin-left: 6px;
  font-size: 14px;
  font-weight: normal;
  color: #666;
}

.interventionText {
  margin-top: 5px;
  font-size: 18px;
  line-height: 120%;
}

.participantsList {
  margin: 36px 0 0 36px;
  list-style-type: disc;
  line-height: 140%;
  color: $primary-blue;
}

.listItem:not(:last-child) {
  margin: 6px 0;
}
