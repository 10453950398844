@import '../../../../styles/constants.scss';

.item {
  margin-bottom: 24px;

  &:last-child,
  &:only-child {
    margin-bottom: 0;
  }
}

.linkContainer {
  margin: 16px 0 36px;
  text-align: right;
}

.link {
  text-decoration: none;
  color: $secondary-blue;
}

.noKudos {
  color: $semantic-placeholder;
}

.footer {
  display: flex;
  justify-content: center;
}

.list + .footer {
  margin-top: 36px;
}
