@import '../../../../../../../../../../styles/constants.scss';

.wrapper {
  margin: 36px 0;
}

.container {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
  }
}
