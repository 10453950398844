.container {
  height: 100%;
  margin-top: 36px;
}

.chart {
  max-width: 100vw;
  height: 100%;
  margin: 0 auto;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
