@import '../../../../../styles/constants.scss';
@import '../../../../../styles/classes/heading.module.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 36px;
  border: 0.5px solid $primary-blue;
  border-radius: $radius-medium;
  background-color: #fff;

  @media screen and (max-width: $breakpoint-medium) {
    height: 100%;
    padding: 12px;
  }
}

.question {
  margin-bottom: 36px;
  color: #000;
  composes: h5;
}

.sliderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.slider {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 20px;
  touch-action: none;
  user-select: none;
}

.sliderValue {
  margin-bottom: 8px;
  composes: h5;
}

.sliderTrack {
  position: relative;
  height: 8px;
  border-radius: $radius-large;
  background-color: $secondary-gray2;
  flex-grow: 1;
}

.sliderRange {
  position: absolute;
  height: 100%;
  border-radius: $radius-large;
  background-color: $primary-blue;
}

.sliderThumb {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: $radius-large;
  background-color: $primary-blue;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  &:focus {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
    outline: none;
  }
}
