// Colors / Primary
$primary-blue: #002554;
$primary-gray: #f5f5f5;
$primary-gray-dark: #6a6a6a;

// Colors / Secondary
$secondary-gray: #d0d2d8;
$secondary-gray2: #e4e5e9;
$secondary-gray3: #fafafb;
$secondary-blue: #08a6f7;
$secondary-light-blue: #cce3ff;

// Colors / Tertiary
$tertiary-blue: #cce3ff;
$tertiary-success: #6ed29c;
$tertiary-warning: #f6c75c;
$tertiary-error: #ff3b6b;

// Colors / Tertiary / Scores
$specific-score-very-high: #6ed29c;
$specific-score-high: #a6d26e;
$specific-score-neutral: #fbbf24;
$specific-score-low: #fe7e07;
$specific-score-very-low: #ff3b6b;

// Colors / Semantics
$semantic-disabled: #bcbec7;
$semantic-error: #8e1818;
$semantic-link: #002554;
$semantic-negative-dark: #8e1818;
$semantic-negative-light: #fcc;
$semantic-notice-light: #fffacc;
$semantic-placeholder: #5e5f64;
$semantic-positive-light: #d6ffcc;
$semantic-success: #2a6409;
$semantic-warning: #b56600;

// Fonts
$primary-font: 'TT Interfaces', sans-serif;
$secondary-font: 'degular', sans-serif;

// Breakpoints
$breakpoint-xxlarge: 1190px;
$breakpoint-xlarge: 1024px;
$breakpoint-large: 860px;
$breakpoint-medium: 768px;
$breakpoint-small: 480px;

// Border Radius
$radius-xsmall: 2px;
$radius-small: 5px;
$radius-medium: 10px;
$radius-large: 24px;
$radius-xlarge: 36px;
$radius-xxlarge: 100px;
$radius-full-circle: 100%;
$radius-half-circle: 50%;
