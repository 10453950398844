@import '../../../../../../../../styles/constants.scss';

.editionList {
  display: flex;
  flex-wrap: wrap;
  margin: 12px 0 0 24px;
  gap: 12px;
}

.editListDisabled {
  color: $semantic-disabled;
  composes: editionList;
  cursor: not-allowed;

  label,
  [disabled] {
    cursor: not-allowed;
  }
}

.editionListItem {
  flex: 1 0 calc(50% - 12px);
}
