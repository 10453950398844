@import '../../../../../styles/constants.scss';

.button {
  display: inline-flex;
  align-items: center;
  min-height: 48px;
  padding: 16px 24px;
  border: 0;
  border-radius: $radius-medium;
  margin: 0;
  background-color: $primary-blue;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  transition: 0.3s ease;
  transition-property: background-color;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #000;
  }

  &:disabled {
    background-color: $semantic-disabled;
    cursor: not-allowed;
  }

  & + .button {
    margin-left: 12px;
  }
}

.secondaryButton {
  border: 1px solid $primary-blue;
  background-color: #fff;
  font-weight: 400;
  color: $primary-blue;
  composes: button;

  &:hover,
  &:focus {
    background-color: $secondary-gray3;
  }

  &:disabled {
    background-color: $semantic-disabled;
    cursor: not-allowed;
  }
}
