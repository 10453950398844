.behaviorContainer {
  margin-top: 36px;
}

.formGroupLabel {
  margin-bottom: 18px;
}

.row {
  margin-bottom: 36px;

  &:last-child {
    margin-bottom: 0;
  }

  &.dimmed {
    opacity: 0.4;
  }
}
