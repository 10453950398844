@import '../../../styles/constants.scss';

.mainPane {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 32px 24px;
  background-color: #fff;

  @media screen and (max-width: $breakpoint-medium) {
    padding: 0;
  }
}
