@import '../../../../styles/constants.scss';

.content {
  margin-top: -72px;

  &.small {
    margin-top: -36px;
  }

  &.none {
    margin-top: 0;
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
  max-width: 1400px;
  padding: 0 60px;
  margin: 0 auto;

  @media screen and (max-width: $breakpoint-xxlarge) {
    padding: 0 24px;
  }

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
  }

  @media screen and (max-width: $breakpoint-medium) {
    padding: 0 12px;
  }
}
