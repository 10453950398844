@import '../../../../../../styles/constants.scss';
@import '../../../../../../styles/classes/text.module.scss';
@import '../../../../../@components/kit/form/form-group/styles.module.scss';
@import '../../../../../@components/kit/form/label/styles.module.scss';
@import '../../../../../@components/kit/form/form-error/styles.module.scss';
@import '../../../../../@components/tag/styles.module.scss';
@import '../../../../../../styles/classes/heading.module.scss';
@import '../../../../../@components/kit/form/autoresize-textarea/styles.module.scss';

.formContainer {
  display: flex;
  align-content: stretch;

  @media screen and (max-width: $breakpoint-xlarge) {
    flex-direction: column;
  }
}

.sidebar {
  flex: 0 0 10%;
  border-left: 1px solid #000;

  @media screen and (max-width: $breakpoint-xlarge) {
    flex: 0 0 100%;
    order: 1;
    border-bottom: 1px solid #000;
    border-left: 0;
  }
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 12px;

  @media screen and (max-width: $breakpoint-xlarge) {
    flex-direction: row;
  }
}

.tabList {
  @media screen and (max-width: $breakpoint-xlarge) {
    display: flex;
  }
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: $radius-xsmall;
  margin-top: 6px;
  color: $primary-blue;
  transition: 0.3s ease;
  transition-property: background-color, color;
  cursor: pointer;

  &:hover,
  &.active {
    background-color: $primary-blue;
    color: #fff;
  }

  @media screen and (max-width: $breakpoint-xlarge) {
    margin: 0 6px 0 0;
  }
}

.selectedItem {
  background-color: $primary-blue;
  color: #fff;
}

.tab {
  display: none;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 36px;
}

.selectedTab {
  display: flex;
  width: 100%;

  @media screen and (max-width: $breakpoint-xlarge) {
    order: 2;
  }
}

.form {
  border: 1px solid #000;
  border-radius: $radius-small;
}

.formGroupLabel {
  padding-bottom: 18px;
  composes: label;
}

.activeValue {
  margin: 12px 0;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.collapsedValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 36px;
  border: 0.5px solid gray;
  border-radius: $radius-small;
  margin: 12px 0;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.headingContainer {
  display: flex;
  align-items: center;
}

.errorBorder {
  border-color: $semantic-error;
}

.errorTextColor {
  color: $semantic-error;

  .icon {
    color: $semantic-error !important;
  }

  ::placeholder {
    color: $semantic-error !important;
  }
}

.errorIcon {
  margin-right: 24px;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.icon {
  width: 24px;
  height: 24px;
  color: $primary-blue;
}

.labelIcon {
  margin-right: 6px;
}

.label {
  display: flex;
  align-items: center;
  composes: label;
}

.mediaUploadTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  color: #000;
  composes: h5;
}

.selectInput {
  margin: 24px 0;
}

.mediaImage {
  height: 275px;
  border-radius: $radius-small;
  margin-top: 12px;
}

.editIcon {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
}

.mediaYoutube {
  height: 275px;
  border-radius: $radius-small;
  margin-top: 12px;
  aspect-ratio: 16 / 9;
}

.formError {
  text-align: right;
  composes: error;
  composes: paragraphSmall;
}

.textarea {
  composes: textarea;
}

.footer {
  width: 100%;
  height: 5px;
  border-top: 1px solid #000;
}

.footerStrip {
  width: 35%;
  height: 100%;
  background-color: $primary-blue;
}
