@import '../../../../../../styles/constants.scss';
@import '../../../../../../styles/classes/heading.module.scss';

.mediaUploadTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  color: #000;
  composes: h5;
}

.mediaImage {
  height: 275px;
  border-radius: $radius-small;
  margin-top: 12px;
}

.editIcon {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
}

.mediaYoutube {
  height: 275px;
  border-radius: $radius-small;
  margin-top: 12px;
  aspect-ratio: 16 / 9;
}
