@import '../../../styles/constants.scss';

.bar {
  overflow: hidden;
  height: 24px;
  border-radius: $radius-small;
  background-color: $primary-gray;

  &.small {
    width: 168px;
  }

  &.xsmall {
    width: 100%;
    height: 16px;
  }

  &.flexible {
    width: 100%;
  }
}

.progress {
  width: 0;
  height: 100%;
  background-color: $tertiary-blue;
  transition: width 0.2s ease-in-out;
}
