@import '../../../../styles/constants.scss';

.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.inputContainer {
  position: relative;
}

.searchIcon {
  position: absolute;
  top: 6px;
  left: 12px;
  width: 24px;
  height: 24px;
}

.input {
  padding: 9px 16px 8px 42px;
  border: 1px solid $primary-blue;
  border-radius: $radius-xlarge;
  background: #fff;
  font-size: 14px;

  &::-webkit-search-cancel-button {
    appearance: none;
  }
}

.button {
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.clearSearchIcon {
  width: 20px;
  height: 20px;
}
