@import '../../../../../styles/constants.scss';

.checkbox {
  display: none;
}

.label {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 45px;
  height: 25px;
  border-radius: $radius-xxlarge;
  background: $secondary-gray;
  transition: 0.2s ease;
  transition-property: background-color;
  cursor: pointer;

  &.isChecked {
    background: $primary-blue;
  }
}

.button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: $radius-xxlarge;
  background: #fff;
  box-shadow: 0 0 2px rgba(10, 10, 10, 0.29);
  transition: 0.2s;
}

.checkbox:checked + .label .button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.label:active .button {
  width: 25px;
}
