@import '../../../styles/constants.scss';
@import '../../../styles/classes/heading.module.scss';

.column {
  flex: 1 1 auto;

  @media screen and (max-width: $breakpoint-large) {
    flex: 0 0 100%;
  }

  &:last-child {
    flex: 0 1 420px;
    min-width: 380px;
    margin-left: 36px;

    @media screen and (max-width: $breakpoint-xlarge) {
      min-width: 300px;
    }

    @media screen and (max-width: $breakpoint-large) {
      min-width: auto;
      margin: 36px 0 0;
    }
  }
}
