@import '../../../../styles/constants.scss';

.formHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;

  @media screen and (max-width: $breakpoint-xlarge) {
    flex-direction: column;
  }
}

.formContent {
  margin-top: 24px;
}

.formFooter {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.role {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 24px;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.roleItem {
  width: 50%;
  padding-right: 20px;

  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 24px;
  }
}

.timerRoleItem {
  display: flex;
  gap: 12px;
}

.timerRoleItemDigit {
  width: 50%;
}

.timerRoleItemAmPm {
  width: 50%;
}
