@import '../../../../../../../styles/constants.scss';

.header {
  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
    gap: 20px;
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: 6px;
}

.buttonIcon {
  width: 24px;
  height: 24px;
  color: $primary-blue;
}
