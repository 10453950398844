@import '../styles.module.scss';
@import '../../../../styles/classes/text.module.scss';
@import '../../../../styles/constants.scss';

.header {
  margin-bottom: 0;
  composes: header;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
