@import '../../../../../../../../../styles/constants.scss';
@import '../../../../../../../../@components/kit/form/button/styles.module.scss';

.companyContainer {
  display: flex;
  align-items: center;
}

.profilePicture {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  border-radius: $radius-half-circle;
  margin-right: 18px;
  object-fit: cover;
}

.avatarContainer {
  margin-right: 18px;
}

.companyLink {
  text-align: left;
  font-size: 14px;
}

.emptyValue {
  padding-left: 6px;
}

.button {
  text-decoration: none;
  composes: secondaryButton;
}
