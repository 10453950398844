@import '../../../styles/constants.scss';

.tag {
  display: inline-flex;
  align-items: center;
  min-height: 36px;
  padding: 8px 12px;
  border-radius: $radius-xxlarge;
  background: $primary-gray;
  text-align: center;
  font-family: $primary-font;
  font-size: 14px;
  color: #000;

  &.dark {
    background: $primary-blue;
    color: #fff;
  }

  &.black {
    background: #000;
    color: #fff;
  }

  &.disabled {
    color: $semantic-disabled;
  }

  &.light-blue {
    background: #cce3ff;
    color: #000;
  }

  &.light {
    background: #fff;
  }

  &.light-yellow {
    background: $semantic-notice-light;
  }

  &.light-red {
    background: $semantic-negative-light;
  }

  &.light-green {
    background: $semantic-positive-light;
  }

  &.score-very-high {
    background: rgba($specific-score-very-high, 0.2);
  }

  &.score-high {
    background: rgba($specific-score-high, 0.2);
  }

  &.score-medium {
    background: rgba($specific-score-neutral, 0.2);
  }

  &.score-low {
    background: rgba($specific-score-low, 0.2);
  }

  &.score-very-low {
    background: rgba($specific-score-very-low, 0.1);
  }

  &.light-purple {
    background: #dcccff;
  }

  &.clickable {
    transition: 0.3s ease;
    transition-property: background;
    cursor: pointer;

    &:hover,
    &:focus {
      background: darken($primary-gray, 3%);
    }
  }
}
