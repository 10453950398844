@import '../../../../../../styles/constants.scss';

.headerRow {
  display: flex;
  align-items: center;
  margin-top: -30px;
  font-size: 16px;
  color: #fff;

  @media screen and (max-width: $breakpoint-small) {
    margin-top: -10px;
  }
}

.duration {
  margin-left: 6px;

  &::before {
    content: '(';
  }

  &::after {
    content: ')';
  }
}
