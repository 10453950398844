@import '../../../../../../styles/classes/heading.module.scss';
@import '../../../../../../styles/classes/text.module.scss';
@import '../../../../../@components/kit/form/button/styles.module.scss';

.title {
  margin-bottom: 36px;
  color: #000;
  composes: h1;
}

.description {
  margin: 0 0 36px;
  composes: paragraph;
}

.link {
  text-decoration: none;
  composes: button;
}

.linkIcon {
  margin-left: 12px;
}
