@import '../../styles/constants.scss';

.wrapper {
  align-items: stretch;
  height: 100%;
  min-height: 100vh;

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: column;
  }
}

.container {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  min-height: 100vh;
  padding-left: 60px;
  background: #f5f5f5;

  @media screen and (max-width: $breakpoint-small) {
    padding-left: 0;
  }
}

.fullWidthContainer {
  padding-left: 0;
  composes: container;
}
