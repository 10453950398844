@import '../../../../../styles/classes/heading.module.scss';
@import '../../../../../styles/classes/text.module.scss';
@import '../../../../../styles/constants.scss';

.header {
  display: flex;
  align-items: center;
}

.headerTitle {
  margin-bottom: 0;
  color: #000;
  composes: h3;
}

.headerIcon {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}

.description {
  color: #000;
  composes: paragraph;
}
