@import '../constants.scss';

.tableHeader {
  @media screen and (max-width: $breakpoint-medium) {
    display: none;
  }
}

.mobileHeaderTitle {
  display: none;

  @media screen and (max-width: $breakpoint-medium) {
    display: flex;
    flex: 0 0 120px;
    width: 120px;
    margin-right: 36px;
    font-weight: 700;
  }

  @media screen and (max-width: $breakpoint-small) {
    width: 100%;
    margin: 0 0 12px;
    flex-basis: auto;
  }
}

.tableRow {
  @media screen and (max-width: $breakpoint-medium) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 12px 24px;
    border-radius: $radius-small;
    background: $secondary-gray3;
  }
}

.tableData {
  @media screen and (max-width: $breakpoint-medium) {
    flex: 0 0 100% !important;
    overflow: auto !important;
    width: auto !important;
    margin: 0 0 12px !important;
  }

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.overflowContainer {
  display: flex;
  align-items: center;
  width: 215px;

  @media screen and (max-width: $breakpoint-medium) {
    width: auto;
  }
}

.overflowContent {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  @media screen and (max-width: $breakpoint-medium) {
    display: block;
    overflow: auto;
    word-break: break-all;
  }
}
