@import '../../../../../../styles/constants.scss';
@import '../../../../../@components/kit/form/button/styles.module.scss';

.container {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
  }
}

.assessment {
  width: 65%;
  padding: 20px;
  border: 1px solid $primary-gray;
  border-radius: $radius-small;
  margin-right: 10px;

  @media screen and (max-width: $breakpoint-large) {
    width: 100%;
  }
}

.header {
  margin-bottom: 24px;
  font-family: $secondary-font;
  font-size: 24px;

  @media screen and (max-width: $breakpoint-large) {
    font-size: 20px;
  }
}

.modules {
  width: 35%;
  min-width: 250px;

  @media screen and (max-width: $breakpoint-large) {
    width: 100%;
    min-width: auto;
    margin-top: 24px;
  }
}

.progressBar {
  width: 100%;
  margin: 24px 0;

  @media screen and (max-width: $breakpoint-large) {
    width: 100%;
    min-width: auto;
  }
}

.viewResultsButton {
  text-decoration: none;
  composes: secondaryButton;
}

.startAssessmentButton {
  text-decoration: none;
  composes: button;
}
