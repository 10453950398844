@import '../../../../../../../../../../styles/constants.scss';

.list {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.listItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primary-blue;
}

.progressbar {
  max-width: 100px;
  margin-bottom: 15px;

  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global(.CircularProgressbar-text) {
    fill: #000;
    font-size: 16px;
    font-weight: 700;
  }
}
