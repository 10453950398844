@import '../../../../../../styles/constants.scss';

.list {
  display: flex;
  align-items: center;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.item {
  margin-left: 48px;

  &:first-child {
    margin-left: 0;
  }

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 0 12px;

    &:last-child {
      margin: 0;
    }
  }
}

.link {
  border-bottom: 1px solid transparent;
  text-decoration: none;
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  transition: 0.3s ease;
  transition-property: border-bottom-color;

  &:hover,
  &:focus {
    border-bottom-color: #fff;
  }
}

.activeLink {
  border-bottom-color: #fff;
  composes: link;
}
