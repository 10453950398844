@import '../../../../../styles/constants.scss';

.form {
  max-width: 540px;
}

.backLink {
  display: inline-flex;
  align-items: center;
  margin-bottom: 36px;
  text-decoration: none;
  font-size: 12px;
  color: $primary-blue;
  transition: 0.3s ease;
  transition-property: color;

  &:hover,
  &:focus {
    color: #000;
  }
}

.backLinkIcon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.formTitle {
  margin-bottom: 12px;
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.instruction {
  margin: 12px 0 36px;
  font-family: $secondary-font;
  font-size: 36px;
  font-weight: 400;
  line-height: 1.2;
  color: #000;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
}

.fields {
  max-width: 343px;
}

.companyLogoContainer {
  display: flex;
  flex-direction: column;
}
