@import '../../../../../../styles/constants.scss';

.list {
  margin: 0 -12px;
}

.link {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: $radius-small;
  text-decoration: none;
  font-size: 16px;
  color: $semantic-link;
  transition: 0.3s ease;
  transition-property: background-color;

  &:hover,
  &:focus {
    background-color: $primary-gray;
  }
}
