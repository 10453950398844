@import '../../../../../../styles/constants.scss';
@import '../../styles.module.scss';
@import '../../../../../@components/kit/form/button/styles.module.scss';

.cardContainer {
  width: 100%;
}

.boxContent {
  align-items: flex-start;
  composes: boxItem;
}

.boxHeader {
  display: flex;
  align-items: baseline;
}

.button {
  min-height: 0;
  padding: 6px;
  margin-left: 12px;
  composes: secondaryButton;
}
