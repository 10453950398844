@import '../../../../../../../../../../../styles/constants.scss';
@import '../../../../../../../../../../@components/kit/form/button/styles.module.scss';

.item {
  margin-bottom: 12px;
}

.row {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.emptyValue {
  display: inline-flex;
  margin: 0 6px;
}

.moduleRow {
  display: flex;
  align-items: center;
  margin: 12px 0 12px 10px;
  font-size: 14px;
  transition: height ease 0.9s;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.content {
  padding: 0.5rem;
  border: 1px solid rgb(128, 123, 123);
  border-radius: $radius-small;
}

.buttonIcon {
  transform: rotate(90deg);
  width: 24px;
  height: 24px;
  transition: 0.3s ease;
  transition-property: transform;
  float: right;

  &.isHidden {
    transform: rotate(-90deg);
  }
}
