@import '../../../../../../../../../../styles/classes/heading.module.scss';
@import '../../../../../../../../../../styles/constants.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 0 18px 0 0;
  color: #000;
  composes: h3;

  @media screen and (max-width: $breakpoint-small) {
    margin: 0 0 18px;
  }
}

.editIcon {
  width: 20px;
  height: 20px;
  color: $primary-blue;
}

.button {
  padding: 5px;
  margin: 0 5px;
}

.deleteIcon {
  width: 20px;
  height: 20px;
  color: $semantic-error;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.colorTag {
  display: flex;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 12px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
}
