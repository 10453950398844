@import '../../../../../../../../styles/constants.scss';

.title {
  font-size: 16px;
  font-weight: 700;
  color: $primary-blue;
}

.blurredTitle {
  composes: title;
  filter: blur(0.9px);
}

.content {
  margin: 12px 0 0;
}

.skeletonImage {
  margin-bottom: 12px;
}

.disabledText {
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
  color: rgba($primary-blue, 0.5);
}

.graphContainer {
  display: flex;
  justify-content: center;
}

.progressbar {
  max-width: 100px;

  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global(.CircularProgressbar-text) {
    fill: rgba(#000, 0.5);
    font-size: 16px;
    font-weight: 700;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.footerColumn {
  display: flex;
  align-items: center;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  color: #000;
}
