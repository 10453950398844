@import '../../../../../../../../styles/constants.scss';
@import '../../../../styles.module.scss';
@import '../../../../../../../@components/kit/form/label/styles.module.scss';
@import '../../../../../../../@components/kit/form/form-error/styles.module.scss';

.collapseContainer {
  composes: collapseContainer;
}

.textContainer {
  composes: collapseContent;
}

.boxTitle {
  composes: collapseHeading;
}

.activePill {
  background-color: $primary-blue;
  color: #fff;
  composes: collapsePill;
}

.icon {
  composes: icon;
}

.action {
  composes: collapseAction;
}

.buttonContainer {
  composes: buttonContainer;
}

.fieldBox {
  composes: fieldBox;
}

.formGroup {
  composes: formGroup;
}

.flexLabel {
  display: flex;
  align-items: center;
  padding-bottom: 0;
  composes: label;
}

.boxHeadingContainer {
  display: flex;
  align-items: center;
}

.formError {
  text-align: right;
  composes: error;
  composes: paragraphSmall;
}

.errorBorder {
  border-color: $semantic-error;
}

.errorTextColor {
  color: $semantic-error;
}

.errorIcon {
  margin-right: 12px;
  color: $semantic-error;
}
