@import '../../../../../../../../../@components/kit/form/button/styles.module.scss';

.message {
  display: flex;
}

.button {
  margin-top: 16px;
  text-decoration: none;
  composes: button;
}
