@import '../../../../styles/constants.scss';

.wrapper {
  position: fixed;
  z-index: 22;
  top: 0;
  left: 0;
  overflow-y: scroll;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.container {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  padding: 80px;

  &.large {
    @media screen and (max-width: $breakpoint-large) {
      padding: 0;
    }
  }

  @media screen and (max-width: $breakpoint-xlarge) {
    padding: 0 40px 40px;
  }

  @media screen and (max-width: $breakpoint-medium) {
    justify-content: flex-start;
    padding: 0;
  }
}

.innerContainer {
  position: relative;
  width: 100%;
  max-width: 600px;
  border-radius: $radius-medium;
  margin: auto;
  background: #fff;

  .small & {
    max-width: 450px;
  }

  .medium & {
    max-width: 705px;
  }

  .large & {
    max-width: 1000px;

    @media screen and (max-width: $breakpoint-large) {
      width: 100%;
      border-radius: 0;
    }
  }

  .xlarge & {
    max-width: 1400px;
  }

  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
  }

  @media screen and (max-width: $breakpoint-small) {
    border-radius: 0;
  }
}
