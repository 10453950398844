@import '../../../styles/constants.scss';
@import '../../../styles/classes/heading.module.scss';
@import '../../@components/kit/form/button/styles.module.scss';

.wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: url('../../../assets/images/bg-swoosh.png') no-repeat bottom left;
  background-size: 100%;
}

.mainPane {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.box {
  max-width: 700px;
  padding: 36px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  margin: 36px 0;
  background-color: #fff;
}

.boxLogo {
  width: 120px;
  margin-bottom: 24px;

  @media screen and (max-width: $breakpoint-medium) {
    width: 72px;
  }
}

.boxTitle {
  margin-bottom: 0;
  composes: h3;
}

.boxMessage {
  margin-top: 24px;
}

.boxList {
  margin: 24px 0 0 24px;
  list-style: square;
}

.boxListItem {
  margin-bottom: 6px;
}

.boxButton {
  margin-top: 36px;
  background-color: $primary-blue;
  font-size: 16px;
  color: #fff;
  composes: button;
}
