@import '../../../../../../styles/constants.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: 6px;
}

.icon {
  width: 24px;
  height: 24px;
  color: $primary-blue;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
  }
}

.headerColumn {
  display: flex;
  gap: 12px;

  @media screen and (max-width: $breakpoint-large) {
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
  }

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
  }
}

.dropdownContainer {
  min-width: 200px;

  @media screen and (max-width: $breakpoint-large) {
    width: 50%;
  }

  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
  }
}
