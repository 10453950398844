@import '../../../../../../../../../../styles/constants.scss';

.button {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $primary-blue;
}

.buttonIcon {
  transform: rotate(-90deg);
  width: 16px;
  height: 16px;
  margin-left: 6px;
  transition: 0.3s ease;
  transition-property: transform;

  &.asc {
    transform: rotate(90deg);
  }
}

.footer {
  margin-top: 12px;
}
