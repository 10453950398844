@import '../../../../../styles/classes/heading.module.scss';
@import '../../../../../styles/classes/text.module.scss';
@import '../../../../@components/kit/form/button/styles.module.scss';

.title {
  margin-bottom: 0;
  text-align: center;
  font-weight: 400;
  color: #000;
  composes: h3;
}

.text {
  margin-top: 24px;
  text-align: center;
  composes: paragraph;
}

.button {
  margin-top: 24px;
  composes: button;
}

.link {
  text-align: center;
  text-decoration: none;
  composes: button;
}

.icons {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
  gap: 12px;
}

.icon {
  width: 24px;
}
