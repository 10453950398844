@import '../../../../styles/constants.scss';

.container {
  height: 100%;
}

.container.standalone {
  position: fixed;
  z-index: 10;
  width: 60px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(#000, 0.1);
  transition: 0.2s ease-in-out;
  transition-property: width;

  &:hover {
    width: 170px;
  }

  @media screen and (max-width: $breakpoint-small) {
    display: none;
  }

  .wrapper {
    padding: 4px 6px 20px;
  }

  .logo {
    display: block;
  }

  .linkText {
    position: absolute;
    left: 55px;
    margin-left: 0;
  }
}

.container.mobile {
  .link {
    justify-content: center;
  }

  .linkText {
    width: auto;
    margin-left: 12px;
  }

  .navigation:last-child {
    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .item {
      margin: 0;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}

.logo {
  display: none;
  width: 45px;
  margin-bottom: 20px;
}

.logo + .list {
  margin-bottom: 6px;
}

.item {
  margin: 0 0 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.mobileItem {
  display: none;

  @media screen and (max-width: $breakpoint-small) {
    display: block;
    margin: 0 0 12px;
  }
}

.link {
  display: flex;
  position: relative;
  align-items: center;
  padding: 12px;
  border-radius: 3px;
  text-decoration: none;
  font-family: $primary-font;
  color: $primary-blue;
  transition: 0.3s ease;
  transition-property: color, background-color;

  &:hover,
  &:focus {
    background-color: #fafafb;
  }
}

.activeLink {
  background-color: $primary-blue;
  color: #fff;
  composes: link;

  &:hover,
  &:focus {
    background-color: $primary-blue;
  }
}

.disabledLink {
  color: #bcbec7;
  composes: link;
  cursor: not-allowed;
  pointer-events: none;

  &:hover,
  &:focus {
    background: none;
  }
}

.linkIcon {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
}

.linkText {
  width: 100px;
  margin-left: 24px;
  text-align: left;
  line-height: 1;
}
