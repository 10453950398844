@import '../../../../../../../../../../../../../../styles/constants.scss';

.item {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 190px;
  padding: 24px;
  border: 1px solid $secondary-gray2;
  border-bottom-left-radius: $radius-small;
  border-top-left-radius: $radius-small;

  @media screen and (max-width: $breakpoint-large) {
    border-bottom-left-radius: 0;
    border-top-right-radius: $radius-small;
  }
}

.title {
  margin-bottom: 20px;
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  color: $primary-blue;
}

.list {
  display: grid;
  height: 24px;
  margin: 20px 0;
  gap: 4px;
  grid-template-columns: repeat(20, 1fr);

  @media screen and (max-width: $breakpoint-small) {
    gap: 2px;
  }

  &.score-very-high {
    .listItem.highlighted {
      background: $specific-score-very-high;
    }
  }

  &.score-high {
    .listItem.highlighted {
      background: $specific-score-high;
    }
  }

  &.score-medium {
    .listItem.highlighted {
      background: $specific-score-neutral;
    }
  }

  &.score-low {
    .listItem.highlighted {
      background: $specific-score-low;
    }
  }

  &.score-very-low {
    .listItem.highlighted {
      background: $specific-score-very-low;
    }
  }
}

.listItem {
  max-width: 8px;
  height: 100%;
  border-radius: $radius-xxlarge;
  background: $primary-gray;
}

.performers {
  font-size: 16px;
}
