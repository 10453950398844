@import '../../../../../../styles/constants.scss';
@import '../../../../../../styles/classes/heading.module.scss';
@import '../../../../../../styles/classes/text.module.scss';

.container {
  display: flex;

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
  }
}

.column {
  flex: 0 1 50%;

  &:first-child {
    margin-right: 36px;
  }

  @media screen and (max-width: $breakpoint-large) {
    flex: 0 0 100%;

    &:first-child {
      margin: 0 0 36px;
    }
  }
}

.row {
  margin-bottom: 36px;

  &:last-child {
    margin-bottom: 0;
  }

  &.dimmed {
    opacity: 0.4;
  }
}

.rowHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowHeaderTitle {
  color: #000;
  composes: h5;
}

.rowContent {
  margin-top: 12px;
  composes: paragraphMedium;
}

.kudosContainer {
  padding: 36px 24px 36px 36px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-medium;
}
