@import '../../../../../../../../styles/constants.scss';

.container {
  width: 100%;
}

.header {
  margin-bottom: 24px;
  text-align: center;
  font-family: $secondary-font;
  font-size: 24px;

  @media screen and (max-width: $breakpoint-large) {
    font-size: 20px;
  }
}

.module {
  padding: 24px;
  border: 1px solid $primary-gray;
  border-radius: $radius-small;
}

.title {
  margin-bottom: 24px;
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 700;
}

.tags {
  display: flex;
  margin-bottom: 24px;
  gap: 6px;
}

.description {
  margin-top: 24px;
}
