@import '../../../../styles/constants.scss';

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.item {
  width: calc((100% / 3) - 12px);

  @media screen and (max-width: $breakpoint-medium) {
    width: calc((100% / 2) - 12px);
  }

  @media screen and (max-width: $breakpoint-small) {
    width: 100%;
  }
}

.button {
  display: flex;
  color: $primary-blue;
}

.icon {
  width: 30px;
  height: 30px;
}

.addButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  text-align: left;
  text-decoration: none;
  color: $primary-blue;
  transition: 0.3s ease;
  transition-property: background;

  &:hover,
  &:focus {
    background: $secondary-gray3;
  }
}
