@import '../../../styles/constants.scss';
@import '../../../styles/classes/heading.module.scss';

.mainPane {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 16px;
  text-align: center;
}

.heading {
  margin-bottom: 36px;
  font-size: 32px;
  composes: h1;
}

.description {
  margin-bottom: 8px;
  font-size: 24px;
  composes: h2;
}
