@import '../../../../../../../../../../styles/classes/table.module.scss';

.disabled {
  opacity: 0.3;
  composes: tableRow;
}

.teamColor {
  display: inline-flex;
  flex: 0 0 20px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 12px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
}

.actionButton {
  padding: 6px 12px;
  border-radius: 100px;
  background: $primary-gray;
  font-size: 13px;
  color: $semantic-link;
  transition: 0.3s ease;
  transition-property: background;

  &:enabled:hover,
  &:enabled:focus {
    background: darken($primary-gray, 3%);
  }
}
