@import '../../../../../../../../styles/classes/heading.module.scss';
@import '../../../../../../../@components/kit/form/button/styles.module.scss';

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.actions {
  display: flex;
  align-items: center;
}

.button {
  padding: 12px 24px;
  margin-left: 12px;
  composes: button;

  &:first-child {
    margin-left: 0;
  }
}

.warningButton {
  border-color: #c51244;
  color: #c51244;
  composes: secondaryButton;
}

.icon {
  width: 24px;
  height: 24px;
  margin-left: 12px;

  &.small {
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
  }
}
