@import '../../../../../../../../../../../styles/classes/heading.module.scss';
@import '../../../../../../../../../../../styles/constants.scss';

.title {
  margin: 0 18px 0 0;
  color: #000;
  composes: h3;

  @media screen and (max-width: $breakpoint-small) {
    margin: 0 0 18px;
  }
}

.subtitle {
  margin: 6px 0 0;
  color: #000;
  composes: h4;
}

.form {
  position: relative;
  margin-bottom: 36px;
}

.contentTitle {
  composes: h5;
}

.columnContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.column {
  flex: 0 0 45%;
}

.disabledBox {
  display: inline-flex;
  padding: 6px 12px;
  border-radius: $radius-small;
  background: $primary-gray;
  color: $primary-blue;
}

.list {
  overflow: auto;
  max-height: 300px;
}

.listItem {
  margin-left: 20px;
  list-style: square;
}
