@import '../../../../styles/constants.scss';

.button {
  display: flex;
  font-weight: bold;

  &.isActive {
    color: $primary-blue;
  }
}

.icon {
  opacity: 0;
  transform: rotate(-90deg);
  width: 18px;
  height: 18px;
  transition: 0.3s ease;
  transition-property: transform, opacity;

  &.sortAsc {
    opacity: 1;
    transform: rotate(-90deg);
  }

  &.sortDesc {
    opacity: 1;
    transform: rotate(90deg);
  }
}
