@import '../../../../../../../../../../../../../../styles/constants.scss';

.container {
  flex: 4;
  padding: 16px;
  border: 1px solid $secondary-gray2;
  border-left: 0;
  font-size: 16px;
  border-bottom-right-radius: $radius-small;
  border-top-right-radius: $radius-small;

  @media screen and (max-width: $breakpoint-large) {
    border-top: 0;
    border-left: 1px solid $secondary-gray2;
    border-bottom-left-radius: $radius-small;
    border-bottom-right-radius: $radius-small;
    border-top-right-radius: 0;
  }
}

.title {
  margin-bottom: 10px;
  font-weight: bold;
}

.list {
  margin: 0 0 20px 20px;
  list-style: disc;
}

.listItem {
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $primary-blue;
}

.buttonIcon {
  transform: rotate(-90deg);
  width: 16px;
  height: 16px;
  margin-left: 6px;
  transition: 0.3s ease;
  transition-property: transform;

  &.asc {
    transform: rotate(90deg);
  }
}
