@import '../../../../../../styles/constants.scss';
@import '../../../../../../styles/classes/heading.module.scss';
@import '../../../../../../styles/classes/text.module.scss';

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.heading {
  margin-bottom: 12px;
  color: #000;
  composes: h3;
}

.secondaryTitle {
  margin-bottom: 8px;
  composes: paragraphLarge;
}

.reflectionDescription {
  margin-bottom: 24px;
  word-break: break-word;
}
