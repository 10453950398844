@import '../../../../../../../@components/kit/form/button/styles.module.scss';

.container {
  max-width: 474px;
  padding: 20px;
  border: 1px solid $primary-gray;
  border-radius: $radius-small;
}

.content {
  display: flex;
  align-items: center;
  gap: 24px;
}

.icon {
  flex: 0 0 36px;
  width: 36px;
  height: 36px;
}

.text {
  max-width: 390px;
  font-size: 16px;
}

.button {
  margin-top: 24px;
  text-decoration: none;
  composes: secondaryButton;
}
