@import '../../../../../../styles/constants.scss';

.error {
  padding: 12px;
  border-radius: $radius-small;
  margin-bottom: 12px;
  background: $semantic-error;
  color: #fff;
}

.footer {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
