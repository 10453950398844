@import '../../../styles/constants.scss';

.fullScreenContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
}

.container {
  text-align: center;
}

.loader {
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 5px solid $primary-gray;
  border-radius: $radius-half-circle;
  animation: rotation 1s linear infinite;
  border-bottom-color: $primary-blue !important;
}

.large {
  width: 48px;
  height: 48px;
  border: 7px solid $primary-gray;
  composes: loader;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
