.formGroup {
  display: block;
  margin-bottom: 36px;
  font-size: 14px;
  line-height: 1.2;

  &.small {
    max-width: 345px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
