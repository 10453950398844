.item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  min-width: 150px;
  margin-right: 12px;
  font-weight: bold;
}
