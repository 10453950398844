.characterCount {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  font-size: 12px;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
