@import '../../../../styles/constants.scss';

.overlay {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.overlayContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  border-radius: $radius-small;
  background: rgba($primary-blue, 0.2);
  pointer-events: all;
}

.healthGaugesContainer {
  margin-top: 36px;
}

.chartContainer {
  margin-top: 24px;
}

.chart {
  position: relative;
  width: 100%;
  height: 400px;
}
