@import '../../../../../../../../../../../styles/constants.scss';

.disabledMessage {
  padding: 12px;
  border-radius: $radius-small;
  margin-bottom: 6px;
  background-color: $secondary-gray2;
  color: $semantic-placeholder;
}

.employeeSelect {
  margin-top: 12px;
}

.calendarContainer {
  display: flex;
  position: relative;
}

.calendarIcon {
  position: absolute;
  top: 12px;
  right: 6px;
  width: 24px;
  height: 24px;
  pointer-events: none;
}
