@import '../../../../../../../../../styles/constants.scss';

.profilePicture {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  border-radius: $radius-half-circle;
  margin-right: 18px;
  object-fit: cover;
}

.avatarContainer {
  margin-right: 18px;
}

.link {
  text-decoration: none;
  color: #000;
  transition: 0.3s ease;
  transition-property: color;

  &:hover,
  &:focus {
    color: $semantic-link;
  }
}

.emptyValue {
  padding-left: 6px;
}

.description {
  position: relative;
  padding-left: 12px;
  margin: 6px 0;
  font-style: italic;
  word-break: break-word;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    border-radius: $radius-xsmall;
    background: $secondary-gray;
  }
}
