@import '../../../../../../../../styles/constants.scss';

.dimension {
  width: fit-content;
  padding: 3px 10px;
  border: 1px solid $semantic-link;
  border-radius: $radius-medium;
  margin-bottom: 10px;
  background-color: $primary-gray;
  font-size: 14px;
}

.description {
  margin: 16px 0 12px;

  li {
    margin-bottom: 16px;
    margin-left: 20px;
  }
}

.description > * {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.subtitle {
  margin: 24px 0 12px;
  font-weight: bold;
}

.dateRow {
  margin: 10px 0;
  font-size: 16px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
