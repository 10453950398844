.calendarContainer {
  display: flex;
  position: relative;
}

.calendarIcon {
  position: absolute;
  top: 12px;
  right: 6px;
  width: 24px;
  height: 24px;
  pointer-events: none;
}
