@import '../../../../../../styles/classes/heading.module.scss';

.container {
  display: flex;
  position: relative;
  z-index: 8;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    align-items: start;
    gap: 16px;
  }
}

.title {
  margin-bottom: 0;
  font-size: 20px;
  composes: h3;
}

.column {
  display: flex;
  align-items: center;
  gap: 6px;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    align-items: flex-start;
  }
}
