@import '../../../styles/constants.scss';
@import '../kit/form/button/styles.module.scss';
@import '../kit/form/input/styles.module.scss';

.chatGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
}

.chatForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  width: 100%;
  padding: 16px 24px;
  gap: 16px;

  @media screen and (max-width: $breakpoint-medium) {
    padding: 8px 16px;
  }
}

.input {
  width: 100%;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.answerBubble {
  padding: 16px 24px;
  border-radius: $radius-medium;
  background-color: $secondary-light-blue;
}

.questionBubble {
  padding: 16px 24px;
  border-radius: $radius-medium;
  background-color: $primary-gray;
}

.optionContainer {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  width: 100%;
  gap: 8px;
}

.optionButton {
  margin-left: 0 !important;
  composes: secondaryButton;
}

.sendButton {
  padding: 4px 16px;
  margin-left: 4px;
  composes: button;
}

.input {
  composes: input;
}

.questionContainer {
  display: flex;
  flex-direction: column;
}

.senderName {
  margin-bottom: 8px;
  font-weight: 600;
  color: $primary-blue;
}
