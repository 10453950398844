@import '../../../../../../../../styles/constants.scss';
@import '../../../../../../../../styles/classes/heading.module.scss';

.container {
  padding: 24px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  margin-bottom: 24px;
  background: $secondary-gray3;
}

.title {
  composes: h4;
}

.message {
  margin-top: 12px;
  line-height: 1.5;
}
