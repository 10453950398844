.column {
  display: flex;
  flex: 0 1 100%;
  align-items: center;
  margin-right: 12px;
  font-size: 14px;

  &:last-child {
    margin-right: 0;
  }

  &.xxsmall {
    flex: 0 0 60px;
  }

  &.xsmall {
    flex: 0 0 110px;
  }

  &.small {
    flex: 0 0 144px;
  }

  &.medium {
    flex: 0 1 216px;
  }

  &.large {
    flex: 0 1 480px;
  }

  &.xlarge {
    flex: 0 1 648px;
  }

  &.nameColumn {
    flex: 0 0 216px;
    overflow: hidden;
    width: 216px;
    margin-right: 24px;
  }
}
