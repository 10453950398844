@import '../../../../../../../../../../styles/constants.scss';

.notReadyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 12px;
}

.primaryMessage {
  font-size: 24px;
  font-weight: 700;
  color: $primary-blue;
}
