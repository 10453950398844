@import '../constants.scss';

.container {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  padding-bottom: 30px;

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
    width: 100%;
  }
}

.containerVertical {
  flex-direction: column;
  max-width: 100%;
  composes: container;
}
