@import '../../../styles/constants.scss';
@import '../../../styles/classes/text.module.scss';

.banner {
  padding: 24px 0;
  background: $primary-gray;

  @media screen and (max-width: $breakpoint-xxlarge) {
    padding: 18px 0;
  }
}

.wrapper {
  max-width: 1400px;
  padding: 0 20px 0 60px;
  margin: 0 auto;
  composes: paragraph;

  @media screen and (max-width: $breakpoint-xxlarge) {
    padding-right: 24px;
    padding-left: 24px;
  }

  @media screen and (max-width: $breakpoint-medium) {
    padding: 0 12px;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  margin-left: 6px;
  text-decoration: none;
  font-size: 16px;
  line-height: 1.4;
  color: $semantic-link;
  transition: 0.3s ease;
  transition-property: color;

  &:hover,
  &:focus {
    color: $primary-blue;
  }

  @media screen and (max-width: $breakpoint-small) {
    display: flex;
    margin-left: 0;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin-left: 3px;
}
