@import '../../../../../../../../styles/constants.scss';

.container {
  display: flex;
  position: relative;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  padding: 6px;
  font-size: 16px;
  font-weight: 700;
  color: $primary-gray-dark;

  &.isOpen {
    .buttonIcon {
      transform: rotate(90deg);
    }
  }

  @media screen and (max-width: $breakpoint-medium) {
    padding-left: 0;
  }
}

.buttonIcon {
  transform: rotate(-90deg);
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  margin-left: 12px;
  transition: 0.3s ease;
  transition-property: transform;
}

.dropdownContainer {
  position: absolute;
  z-index: 1;
  top: 40px;
  right: 0;
  overflow: auto;
  min-width: 300px;
  max-height: 300px;
  border-radius: $radius-small;
  background: #fff;
  box-shadow: 0 1px 10px rgba(#000, 0.1);

  @media screen and (max-width: $breakpoint-medium) {
    left: 0;
    min-width: auto;
  }
}
