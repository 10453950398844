@import '../../../../styles/constants.scss';
@import '../../../@components/kit/form/form-group/styles.module.scss';
@import '../../../../styles/classes/text.module.scss';
@import '../../../../styles/classes/heading.module.scss';

.existingReportsGrid {
  display: grid;
  margin-bottom: 24px;
  column-gap: 24px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: $breakpoint-medium) {
    grid-template-columns: 1fr;
  }
}

.collapseContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 390px;
  padding: 12px 24px 12px 12px;
  border: 1px solid $secondary-gray;
  border-radius: $radius-medium;
  margin: 12px 0 24px;
}

.collapseContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 246px;
  margin: 0 12px;
}

.collapseHeading {
  margin-bottom: 6px;
  font-weight: bold;
  color: #000;
  composes: h5;
}

.collapsePill {
  width: max-content;
  padding: 4px 12px;
  border-radius: $radius-xlarge;
  background-color: $primary-gray;
  line-height: 1;
  composes: paragraphMedium;
}

.collapseAction {
  display: flex;
}

.actionText {
  composes: paragraphMedium;
}

.linkButton {
  margin-left: 3px;
  color: $primary-blue;
}

.buttonContainer {
  margin-top: 24px;
}

.icon {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  margin-left: 12px;
}

.fieldBox {
  max-width: 390px;
  padding: 36px;
  border: 0.5px solid $secondary-gray;
  border-radius: $radius-small;
  margin: 12px 0 24px;
}

.formGroup {
  margin-bottom: 18px;
  composes: formGroup;
}

.pageGroup {
  margin: 12px 0 24px;
}

.pageFooter {
  display: flex;
  margin: 24px 0 0;
}

.dialogText {
  margin-bottom: 24px;
}

.seperator {
  height: 1px;
  margin: 24px 0;
  background-color: $secondary-gray;
}

.errorContainer {
  padding: 12px;
  border: 1px solid $semantic-error;
  border-radius: $radius-medium;
  margin-bottom: 24px;
  background: rgba($semantic-error, 0.1);
  color: $semantic-error;
}
