.list {
  display: flex;
  gap: 24px;
}

.listItem {
  display: flex;
  align-items: center;
}

.label {
  padding-bottom: 0;
  margin-left: 12px;
}
