@import '../../../../../../../../styles/constants.scss';

.calibrationScoreColumn {
  text-align: left;
}

.tableHeader {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 1px solid #000;
  font-size: 14px;
  font-weight: 400;

  @media screen and (max-width: $breakpoint-medium) {
    display: none;
  }
}

.tableData {
  @media screen and (max-width: $breakpoint-medium) {
    display: flex;
    width: 100% !important;
    margin: 0 0 12px !important;
  }

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.content {
  margin-top: 12px;
}

.nameColumn {
  display: flex;
  align-items: center;
  width: 25%;
  margin-right: 6px;
}

.jobPositionColumn {
  width: 20%;
  margin-right: 6px;
}

.assessmentStatusColumn {
  width: 20%;
  margin-right: 6px;
}

.dueDateColumn {
  width: 10%;
  margin-right: 6px;
}

.alignmentScoreColumn {
  width: 15%;
  margin-right: 6px;
  text-align: center;
}

.actionColumn {
  width: 10%;
}
