@import '../../../../styles/constants.scss';
@import '../styles.module.scss';

.header {
  justify-content: space-between;
  composes: header;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  margin-top: 24px;
}

.link {
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-width: 285px;
  min-height: 200px;
  padding: 10px;
  border: 1px solid $secondary-gray2;
  border-radius: $radius-small;
  margin-top: 24px;
  text-decoration: none;
  font-size: 14px;
  color: $primary-blue;
  transition: 0.3s ease;
  transition-property: background-color;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $primary-gray;
  }
}

.icon {
  flex: 0 0 30px;
  margin-right: 12px;
}
