@import '../../../styles/constants.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  color: $primary-blue;
}

.title {
  margin-right: 18px;
}

.bar {
  overflow: hidden;
  height: 24px;
  border: 1px solid $secondary-light-blue;
  border-radius: $radius-small;
  background-color: #fff;
}

.progress {
  width: 0;
  height: 100%;
  background-color: $tertiary-blue;
  transition: width 0.2s ease-in-out;
}
