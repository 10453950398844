@import '../../../../styles/constants.scss';

.linkContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
}

.link {
  border-bottom: 1px solid transparent;
  text-decoration: none;
  font-size: 16px;
  color: $semantic-link;
  transition: 0.3s ease;
  transition-property: border-bottom-color;

  &:hover,
  &:focus {
    border-bottom-color: $semantic-link;
  }
}
