@import '../../../../../../../../../../styles/constants.scss';

.trend {
  display: inline-flex;
  align-items: center;
  padding: 6px;
  border-radius: $radius-small;
  background: rgba(#304e26, 0.1);
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #304e26;

  &.isDecreasing {
    background: rgba($semantic-negative-dark, 0.1);
    color: $semantic-negative-dark;
  }
}

.icon {
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
