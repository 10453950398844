@import '../../../../../../../../styles/constants.scss';
@import '../../../../styles.module.scss';

.cardContainer {
  width: 100%;
}

.boxContent {
  align-items: flex-start;
  composes: boxItem;
}
