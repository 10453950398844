@import '../../../../../styles/classes/text.module.scss';
@import '../../../../../styles/constants.scss';

.description {
  margin-top: 12px;
  composes: paragraph;
}

.item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  border-bottom: 1px solid transparent;
  text-decoration: none;
  font-size: 16px;
  color: $semantic-link;
  transition: 0.3s ease;
  transition-property: border-bottom-color;

  &:hover,
  &:focus {
    border-bottom-color: $semantic-link;
  }
}

.emptyMessage {
  margin-top: -10px;
  font-size: 16px;
  color: $semantic-placeholder;
}

.buttonWrapper {
  margin-top: 20px;
}
