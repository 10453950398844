@import '../../../../../../../../../../../../styles/constants.scss';

.container {
  display: flex;
  align-items: center;
  width: 100%;
}

.button {
  padding: 10px 12px 8px;
  border-radius: $radius-medium;
  margin-left: 12px;
  background-color: $primary-blue;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  transition: 0.3s ease;
  transition-property: background-color;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: #000;
  }
}
