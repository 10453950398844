@import '../constants.scss';

.paragraph {
  font-family: $primary-font;
  font-size: 16px;
  line-height: 1.4;
}

.paragraphExtraLarge {
  font-family: $primary-font;
  font-size: 24px;
  line-height: 1.4;
}

.paragraphLarge {
  font-size: 18px;
  composes: paragraph;
}

.paragraphMedium {
  font-size: 14px;
  composes: paragraph;
}

.paragraphSmall {
  font-size: 12px;
  composes: paragraph;
}

.paragraphExtraSmall {
  font-size: 10px;
  composes: paragraph;
}
