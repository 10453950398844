@import '../../styles.module.scss';

.group {
  margin: 12px 0 24px;
}

.existingReportsGrid {
  display: grid;
  margin-bottom: 24px;
  column-gap: 24px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: $breakpoint-medium) {
    grid-template-columns: 1fr;
  }
}

.text {
  composes: actionText;
}

.linkButton {
  composes: linkButton;
}
