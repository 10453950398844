@import '../../../../../../../../../../styles/constants.scss';

.emptyValue {
  margin-left: 6px;
  color: $semantic-disabled;
}

.profilePicture {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  border-radius: $radius-half-circle;
  margin-right: 18px;
  object-fit: cover;
}

.avatarContainer {
  margin-right: 18px;
}

.link {
  text-decoration: none;
  color: #000;
  transition: 0.3s ease;
  transition-property: color;

  &:hover,
  &:focus {
    color: $semantic-link;
  }
}

.centeredContent {
  width: 100%;
  margin-left: -12px;
  text-align: center;

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0;
    text-align: left;
  }
}
