.header {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 1px solid #000;

  &.borderless {
    border-bottom: 0;
  }
}
