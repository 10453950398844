@import '../../../../styles/constants.scss';

.mediaUploadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 275px;
  padding: 36px;
  border-radius: $radius-small;
  margin-top: 24px;
  background-color: $primary-gray;
}

.controlGroup {
  justify-content: center;
  align-items: center;
}

.mediaUploadButton {
  display: flex;
  align-items: center;
  min-width: 286px;
  padding: 12px;
  border-radius: $radius-small;
  margin: 12px auto;
  background: #fff;

  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
    min-width: auto;
  }
}

.youtubeEmbedArea {
  width: 100%;
  padding: 12px;
  border-radius: $radius-small;
  margin: 12px auto;
  background: #fff;
}

.iconContainer {
  padding-right: 12px;
  border-right: 1px solid $primary-gray;
  margin-right: 12px;
}

.youtubeEmbedInput {
  min-width: 400px;
}

.label {
  margin-bottom: 12px;
}

.embedFormHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  font-weight: bold;
}

.embedFormHeaderColumn,
.embedeFormHeaderbutton {
  display: flex;
  align-items: center;
}

.embedFormHeaderIcon {
  margin-left: 6px;
}
