@import '../../../../../../../../../../../../styles/constants.scss';

.item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
  }
}
