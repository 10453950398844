@import '../../../../../../styles/constants.scss';
@import '../../../../../../styles/classes/heading.module.scss';
@import '../../../../../../styles/classes/text.module.scss';

.titleRow {
  white-space: pre-line;
  composes: paragraphExtraLarge;

  @media screen and (max-width: $breakpoint-medium) {
    font-size: 16px;
  }
}

.list {
  display: flex;
  margin: 36px 0;
  gap: 12px;
}

.heading {
  margin-bottom: 12px;
  color: #000;
  composes: h3;
}

.paragraph {
  margin-bottom: 12px;
  composes: paragraph;
}

.subHeading {
  margin-bottom: 12px;
  color: #000;
  composes: h5;
}

.contentRow {
  margin: 36px 0;
  composes: paragraph;
}

.chartContainer {
  max-width: 560px;
  margin: 0 auto;

  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.chartContainer + .contentRow {
  margin-top: 0;
}
