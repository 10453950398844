@import '../../../../../../../../../../../../styles/constants.scss';

.list {
  padding-bottom: 20px;
  margin-top: -20px;
}

.item {
  padding: 8px 0;
  color: $primary-blue;
}
