.container {
  position: relative;
  width: 100%;

  &.floating-error {
    padding-bottom: 24px;

    .error {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.error {
  text-align: right;
  font-size: 12px;
}
