@import '../../../../../../../../styles/constants.scss';

.list {
  display: flex;
  gap: 12px;
}

.item {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px;
  border: 1px solid $secondary-gray2;
  gap: 10px;
  word-break: break-word;
}
