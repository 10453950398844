@import '../../../../../../../../../../styles/constants.scss';

.errorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background: rgba($semantic-error, 0.1);
  text-align: center;
  font-size: 14px;
  line-height: 1.3;
  color: $semantic-error;
}

.item {
  border-bottom: 1px solid $secondary-gray2;

  &:last-child,
  &:only-child {
    border-bottom: 0;
  }
}
