@import '../../../../../../styles/constants.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: 6px;
}

.buttonIcon {
  width: 24px;
  height: 24px;
  color: $primary-blue;
}
