@import '../../../../../../../styles/constants.scss';

.option {
  z-index: 2;

  .icon {
    display: none;
    width: 16px;
    height: 16px;
    margin: auto;
  }
}

.radio {
  display: none;
}

.radio + .radioLabel {
  display: flex;
  z-index: 1;
  width: 24px;
  height: 24px;
  border: 1px solid $primary-blue;
  border-radius: $radius-half-circle;
  background: #fff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

input[type='radio'].radio:checked + .radioLabel {
  background: $primary-blue;
  color: white;
}

input[type='radio'].radio:checked + .radioLabel .icon {
  display: block;
}
