@import '../../../styles/constants.scss';

.container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: url('../../../assets/images/bg-swoosh.png') no-repeat bottom left;
  background-size: 100%;
}

.content {
  display: flex;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
  }
}

.introPanel {
  z-index: 1;
  width: 100%;
  max-width: 582px;
  min-height: 100%;
}

.animatedIntroPanel {
  composes: introPanel;

  @media screen and (max-width: $breakpoint-medium) {
    display: none;
  }
}

.staticIntroPanel {
  display: none;
  composes: introPanel;

  @media screen and (max-width: $breakpoint-medium) {
    display: block;
  }
}

.rightPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 96px;

  @media screen and (max-width: $breakpoint-medium) {
    flex: 1;
    min-height: 100vh;
    padding-top: 60px;
  }
}

.progressBar {
  width: 100%;
  max-width: 454px;
  padding: 36px 12px;
}
