.percentage {
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.icon {
  flex: 0 0 18px;
  width: 18px;
  height: 25px;
  margin-left: 6px;
}
