@import '../../../../../../../styles/constants.scss';
@import '../../../../../../../styles/classes/text.module.scss';

.option {
  position: relative;

  &:first-of-type {
    .label {
      @media screen and (max-width: $breakpoint-medium) {
        padding-top: 0;
      }
    }
  }
}

.label {
  display: flex;
  position: relative;
  z-index: 2;
  flex-direction: column;
  align-items: center;
  min-width: 70px;
  max-width: 102px;
  min-height: 84px;
  padding: 0 6px 6px;

  .icon {
    display: none;
    width: 16px;
    height: 16px;
    margin: auto;
  }

  &:hover .fakeRadio,
  &:focus .fakeRadio {
    background: $primary-gray;
  }

  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    min-height: auto;
    padding: 12px 6px;
  }
}

.radio {
  display: none;

  &:checked + .fakeRadio {
    background: $primary-blue;
    color: white;

    .icon {
      display: block;
    }
  }
}

.fakeRadio {
  display: flex;
  z-index: 12;
  width: 24px;
  height: 24px;
  border: 1px solid $primary-blue;
  border-radius: $radius-half-circle;
  background: #fff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.text {
  margin-top: 12px;
  text-align: center;
  composes: paragraphSmall;

  @media screen and (max-width: $breakpoint-medium) {
    margin: 0 0 0 12px;
  }
}
