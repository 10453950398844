@import '../../../../../../../../../../../../styles/constants.scss';

.score {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;

  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global(.CircularProgressbar-text) {
    fill: $primary-blue;
    font-size: 24px;
    font-weight: 700;
  }

  @media screen and (max-width: $breakpoint-large) {
    flex: 0 0 50px;
    width: 40px;
    height: 40px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  min-width: 275px;

  @media screen and (max-width: $breakpoint-large) {
    flex-direction: row;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid $secondary-gray2;
  margin: 0 8px 6px 0;
  font-size: 17px;
  font-weight: bold;
  color: $primary-blue;
  transition: 0.3s ease;
  transition-property: background, border;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: rgba(228, 240, 255, 0.45);
  }

  &.active {
    border-left: 5px solid $primary-blue;
    background-color: rgba(228, 240, 255, 0.45);
  }

  @media screen and (max-width: $breakpoint-large) {
    padding: 9px;
    font-size: 15px;
  }
}
