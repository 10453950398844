@import '../../../../../styles/constants.scss';

.input {
  width: 100%;
  padding: 14px 12px;
  border: 0;
  border-bottom: 1px solid #000;
  background: transparent;
  font-family: $primary-font;
  font-size: 14px;
  color: #000;
  transition: 0.3s ease;
  transition-property: background, border-bottom-color;
  appearance: none;

  &:focus {
    background: #fafafb;
    outline: none;
  }

  &::placeholder {
    color: $semantic-placeholder;
  }

  &.error {
    color: $semantic-error;
    border-bottom-color: $semantic-error;

    &::placeholder {
      color: $semantic-error;
    }
  }

  &[disabled] {
    color: $semantic-disabled;
    border-bottom-color: $semantic-disabled;
  }
}
