@import '../../../../../../../../../styles/constants.scss';

.tableHeader {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 1px solid #000;
  font-size: 14px;
  font-weight: 400;

  @media screen and (max-width: $breakpoint-medium) {
    display: none;
  }
}

.tableData {
  @media screen and (max-width: $breakpoint-medium) {
    display: flex;
    width: 100% !important;
    margin: 0 0 12px !important;
  }

  @media screen and (max-width: $breakpoint-small) {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.nameColumn {
  display: flex;
  align-items: center;
  width: 25%;
  margin-right: 6px;

  @media screen and (max-width: $breakpoint-small) {
    margin-right: 0;
  }
}

.scheduleColumn {
  width: 10%;
  margin-right: 6px;
}

.startDateColumn,
.populationColumn {
  width: 15%;
  margin-right: 6px;
}

.actionsColumn {
  display: flex;
  flex-wrap: wrap;
  width: 40%;
  gap: 6px;
}

.content {
  margin-top: 12px;
}
