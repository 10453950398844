@import '../../../../../styles/constants.scss';

.container {
  text-align: center;
}

.message {
  margin-top: 20px;
  font-family: $secondary-font;
  font-size: 24px;
  font-weight: 700;
}

.messageSubText {
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
}
