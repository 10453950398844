@import '../../../@components/kit/form/form-group/styles.module.scss';
@import '../../../@components/tag/styles.module.scss';
@import '../../../../styles/constants.scss';

.formGroup {
  display: flex;
  align-items: flex-start;
  margin-bottom: 18px;
  composes: formGroup;

  @media screen and (max-width: $breakpoint-xxlarge) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.tag {
  justify-content: center;
  min-width: 170px;
  margin-right: 24px;
  composes: tag;

  @media screen and (max-width: $breakpoint-xxlarge) {
    margin: 0 0 12px;
  }
}

.behaviorContainer {
  width: 100%;
}
