@import '../../../../../styles/constants.scss';

.content {
  padding: 0 36px 36px;
  border-radius: 0 0 $radius-medium $radius-medium;
  font-family: $primary-font;

  &.spaceless {
    margin-top: -50px;
  }

  @media screen and (max-width: $breakpoint-medium) {
    padding: 0 18px 24px;
    border-radius: 0;
  }
}
