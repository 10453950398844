@import '../../../../../styles/constants.scss';

.searchFormContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.searchFormButtonText {
  @media screen and (max-width: $breakpoint-medium) {
    display: none;
  }
}

.searchFormButtonIcon {
  display: none;
  width: 24px;
  height: 24px;
  stroke-width: 2px;

  @media screen and (max-width: $breakpoint-medium) {
    display: block;
  }
}

.header {
  @media screen and (max-width: $breakpoint-large) {
    flex-direction: column;
    gap: 20px;
  }
}
