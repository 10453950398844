@import '../../../styles/constants.scss';
@import '../../../styles/classes/heading.module.scss';

.swooshBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: initial;
  height: 50%;
  object-fit: contain;
}

.wrapper {
  display: flex;
  height: 100vh;
}

.mainPane {
  display: flex;
  position: relative;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.secondaryPane {
  z-index: 1;
  flex: 1;
  background-color: #002554;
  color: #fff;

  > div {
    padding: 96px;
  }

  h1 {
    margin-top: 36px;
    font-family: 'degular', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 48px;
    color: #fff;
  }
}

@media screen and (max-width: $breakpoint-xlarge) {
  .wrapper {
    flex-direction: column;
  }

  .mainPane {
    flex: initial;
    align-items: initial;
    width: auto;
    height: initial;
    margin-top: 40px;
  }

  .secondaryPane {
    flex: initial;

    > div {
      padding: 48px 36px;
    }

    h1 {
      font-size: 32px;
    }
  }
}
